import React, { useState, useEffect, useContext } from 'react';
import { GlobalLanguageContext } from '../../components/LanguageSwitcher';
import sanityClient from '../../sanity';
import { motion } from 'framer-motion';
import { ArrowRight, Clock, Users } from 'lucide-react';

const JoinusSection = () => {
  const { globalLanguage } = useContext(GlobalLanguageContext);
  const [careerPosts, setCareerPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const content = {
    en: {
      title: "Join Our Growing Team",
      subtitle: "Explore Career Opportunities",
      readMore: "View Position",
      viewAllPositions: "View All Open Positions",
      positions: "Open Positions",
      noCareersAvailable: "Currently, there are no career opportunities available.",
      loading: "Loading opportunities...",
      errorMessage: "Failed to load positions.",
      defaultImageAlt: "Career Opportunity",
      department: "Department",
      fullTime: "Full Time"
    },
    sq: {
      title: "Bëhu Pjesë e Ekipit Tonë",
      subtitle: "Eksploro Mundësitë e Karrierës",
      readMore: "Shiko Pozicionin",
      viewAllPositions: "Shiko të Gjitha Pozicionet e Lira",
      positions: "Pozicione të Lira",
      noCareersAvailable: "Aktualisht, nuk ka mundësi karriere të disponueshme.",
      loading: "Duke ngarkuar mundësitë...",
      errorMessage: "Dështoi në ngarkimin e pozicioneve.",
      defaultImageAlt: "Mundësi Karriere",
      department: "Departamenti",
      fullTime: "Kohë e Plotë"
    }
  };

  useEffect(() => {
    setLoading(true);
    sanityClient.fetch(
      `*[_type == "careerPost" && language == $language][0...4] | order(publishDate desc) {
        title,
        slug,
        mainImage {
          asset->{
            _id,
            url
          },
          alt
        },
        body,
        publishDate,
        "excerpt": pt::text(body)[0...120]
      }`,
      { language: globalLanguage }
    )
    .then((data) => {
      setCareerPosts(data);
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setError(content[globalLanguage].errorMessage);
      setLoading(false);
    });
  }, [globalLanguage]);

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getPostUrl = (slug) => `/${globalLanguage === 'en' ? 'en/news' : 'sq/lajme'}/${slug}`;

  const renderCareerCard = (post, index) => (
    <motion.div 
      key={post.slug.current}
      className="relative bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-all duration-300"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
    >
      <div className="absolute top-3 right-3 bg-white/90 backdrop-blur-sm px-2 py-1 rounded-full text-xs font-medium text-red-600">
        {content[globalLanguage].fullTime}
      </div>
      
      <a 
        href={getPostUrl(post.slug.current)}
        className="block h-36 overflow-hidden group"
      >
        <img
          src={post.mainImage?.asset?.url || 'https://via.placeholder.com/400x300'}
          alt={post.mainImage?.alt || content[globalLanguage].defaultImageAlt}
          className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
        />
      </a>
      
      <div className="p-4">
        <div className="flex items-center text-xs text-gray-500 mb-2">
          <Clock className="w-3 h-3 mr-1" />
          <span>{formatDate(post.publishDate)}</span>
          <Users className="w-3 h-3 ml-3 mr-1" />
          <span>{content[globalLanguage].department}</span>
        </div>
        
        <a 
          href={getPostUrl(post.slug.current)}
          className="block group"
        >
          <h3 className="text-base font-bold text-gray-900 mb-2 line-clamp-2 min-h-[2.75rem] group-hover:text-red-600 transition-colors duration-300">
            {post.title}
          </h3>
        </a>
        
        <p className="text-sm text-gray-600 mb-4 line-clamp-2 min-h-[2.5rem]">
          {post.excerpt}
        </p>
        
        <div className="flex justify-between items-center pt-3 border-t border-gray-100">
          <a
            href={getPostUrl(post.slug.current)}
            className="inline-flex items-center text-sm text-red-600 font-semibold hover:text-red-700 transition-colors duration-300 group"
          >
            {content[globalLanguage].readMore}
            <ArrowRight className="ml-1 h-3 w-3 transform group-hover:translate-x-1 transition-transform" />
          </a>
        </div>
      </div>
    </motion.div>
  );

  if (loading) return (
    <div className="flex items-center justify-center py-16">
      <div className="text-center">
        <div className="w-12 h-12 border-3 border-gray-200 border-t-red-600 rounded-full animate-spin mb-3"></div>
        <p className="text-sm text-gray-600">{content[globalLanguage].loading}</p>
      </div>
    </div>
  );

  if (error) return (
    <div className="text-center py-12 px-4">
      <div className="max-w-md mx-auto">
        <p className="text-red-600 text-base">{error}</p>
      </div>
    </div>
  );

  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-3">
            <span className="text-red-600">{content[globalLanguage].title}</span>
          </h2>
          <p className="text-lg text-gray-600">
            {content[globalLanguage].subtitle}
          </p>
        </motion.div>

        {careerPosts.length > 0 ? (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
              {careerPosts.map((post, index) => renderCareerCard(post, index))}
            </div>
            
            <motion.div 
              className="text-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5 }}
            >
              <a
                href={`/${globalLanguage === 'en' ? 'en/team/join-us' : 'sq/ekipi/bashkohuni-me-ne'}`}
                className="inline-flex items-center px-6 py-3 text-base font-semibold text-white bg-red-600 rounded-lg hover:bg-red-700 transition-all duration-300 transform hover:scale-105 hover:shadow-lg group"
              >
                {content[globalLanguage].viewAllPositions}
                <ArrowRight className="ml-2 h-4 w-4 transform group-hover:translate-x-2 transition-all duration-300" />
              </a>
            </motion.div>
          </>
        ) : (
          <div className="text-center bg-white rounded-lg shadow-md py-8 px-4">
            <p className="text-gray-600">
              {content[globalLanguage].noCareersAvailable}
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

export default JoinusSection;