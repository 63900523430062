// src/App.js
import React from 'react';
import './Contact.css';
import ContactForm from './ContactForm';


function Contact() {
  return (
    <div className="App">
    <ContactForm/>
    </div>
  );
}

export default Contact;