import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import { useLanguage } from '../../components/languageUtils';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';

const EMAILJS_SERVICE_ID = 'service_qjpj0fs';
const EMAILJS_TEMPLATE_ID = 'template_a4bp2k7';
const EMAILJS_PUBLIC_KEY = 'p-K0sXb449NWZX-83';

const ContactForm = () => {
  const form = useRef();
  const [submitStatus, setSubmitStatus] = useState(null);
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  // Initialize EmailJS
  useEffect(() => {
    try {
      emailjs.init(EMAILJS_PUBLIC_KEY);
      console.log('EmailJS initialized successfully');
    } catch (error) {
      console.error('EmailJS initialization error:', error);
    }

    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timer);
  }, []);

  const content = {
    en: {
      contactUs: "CONTACT US",
      getInTouch: "GET IN TOUCH",
      workingHours: "Mon-Fri: 08:00-17:00",
      email: "info@aicorporation.al",
      address: "St. Ibrahim Rugova 5, Sky Tower 9/1, Tirana, Albania",
      coordinatorTitle: "Coordinator for the Right to Information",
      coordinatorName: "Grei Flamuraj",
      coordinatorPhone: "+355 69 931 5335",
      coordinatorEmail: "grei.flamuraj@aiccorporation.al",
      firstName: "First name*",
      lastName: "Last name*",
      yourEmail: "Your E-mail*",
      phoneNumber: "Your Phone Number",
      companyName: "Company Name",
      addressPlaceholder: "Address*",
      message: "Your message*",
      send: "Send Message",
      sending: "Sending...",
      sent: "Sent Successfully",
      failed: "Failed to Send"
    },
    sq: {
      contactUs: "NA KONTAKTONI",
      getInTouch: "LIDHUNI ME NE",
      workingHours: "E Hënë-E Premte: 08:00-17:00",
      email: "info@aicorporation.al",
      address: "Rr. Ibrahim Rugova 5, Sky Tower 9/1, Tiranë, Shqipëri",
      coordinatorTitle: "Koordinator për të Drejtën e Informimit",
      coordinatorName: "Grei Flamuraj",
      coordinatorPhone: "+355 69 931 5335",
      coordinatorEmail: "grei.flamuraj@aiccorporation.al",
      firstName: "Emri*",
      lastName: "Mbiemri*",
      yourEmail: "E-maili juaj*",
      phoneNumber: "Numri juaj i telefonit",
      companyName: "Emri i kompanisë",
      addressPlaceholder: "Adresa*",
      message: "Mesazhi juaj*",
      send: "Dërgo Mesazhin",
      sending: "Duke dërguar...",
      sent: "U dërgua me sukses",
      failed: "Dërgimi dështoi"
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitStatus('sending');
    
    // Log form data for debugging
    const formData = new FormData(form.current);
    console.log('Form data being sent:', Object.fromEntries(formData));

    try {
      // Validate required fields
      const requiredFields = ['firstName', 'lastName', 'email', 'address', 'message'];
      for (const field of requiredFields) {
        const value = formData.get(field);
        if (!value) {
          throw new Error(`Required field missing: ${field}`);
        }
      }

      // First try to initialize again just in case
      try {
        await emailjs.init(EMAILJS_PUBLIC_KEY);
      } catch (initError) {
        console.log('Re-initialization not needed or failed:', initError);
      }

      // Send the form with additional error logging
      console.log('Attempting to send email...');
      const result = await emailjs.sendForm(
        EMAILJS_SERVICE_ID,
        EMAILJS_TEMPLATE_ID,
        form.current
      );

      console.log('EmailJS Response:', result);

      if (result.status === 200) {
        console.log('Email sent successfully');
        setSubmitStatus('success');
        form.current.reset();
      } else {
        throw new Error(`EmailJS returned status ${result.status}`);
      }
    } catch (error) {
      console.error('Detailed submission error:', {
        message: error.message,
        stack: error.stack,
        text: error.text,
      });
      setSubmitStatus('error');
      
      // Alert for debugging purposes - remove in production
      alert(`Error sending message: ${error.message}. Check console for details.`);
    } finally {
      setTimeout(() => setSubmitStatus(null), 3000);
    }
  };

  const getButtonText = () => {
    switch(submitStatus) {
      case 'sending':
        return content[language].sending;
      case 'success':
        return content[language].sent;
      case 'error':
        return content[language].failed;
      default:
        return content[language].send;
    }
  };

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <motion.div
      className="max-w-7xl mx-auto my-10 font-sans bg-gray-100 p-8 rounded-2xl"
      ref={ref}
      initial="hidden"
      animate={inView && !loading ? "visible" : "hidden"}
      variants={{
        hidden: {},
        visible: {
          transition: {
            staggerChildren: 0.2,
          },
        },
      }}
    >
      <div className="flex flex-col lg:flex-row gap-16">
        <motion.div className="lg:w-1/3" variants={fadeInUpVariants}>
          <h2 className="text-lg text-gray-700 mb-1">{content[language].contactUs}</h2>
          <h3 className="text-3xl text-red-600 mb-6">{content[language].getInTouch}</h3>
          <div className="flex items-center mb-4 text-gray-600">
            <span className="mr-3 text-xl">🕒</span>
            <span>{content[language].workingHours}</span>
          </div>
          <div className="flex items-center mb-4 text-gray-600">
            <span className="mr-3 text-xl">✉️</span>
            <span>{content[language].email}</span>
          </div>
          <div className="flex items-center mb-4 text-gray-600">
            <span className="mr-3 text-xl">📍</span>
            <span>{content[language].address}</span>
          </div>
          <motion.div className="mt-8 bg-white p-6 rounded-xl shadow-md" variants={fadeInUpVariants}>
            <h4 className="text-xl font-semibold text-gray-800 mb-3">{content[language].coordinatorTitle}</h4>
            <p className="text-gray-600 mb-2">{content[language].coordinatorName}</p>
            <p className="text-gray-600 mb-2">
              <span className="inline-block w-5 mr-2">📞</span>
              {content[language].coordinatorPhone}
            </p>
            <p className="text-gray-600">
              <span className="inline-block w-5 mr-2">✉️</span>
              {content[language].coordinatorEmail}
            </p>
          </motion.div>
        </motion.div>
        <motion.div className="lg:w-2/3" variants={fadeInUpVariants}>
          <form ref={form} onSubmit={handleSubmit} className="space-y-6">
            <motion.div className="flex flex-col sm:flex-row gap-4" variants={fadeInUpVariants}>
              <input
                type="text"
                name="firstName"
                placeholder={content[language].firstName}
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
              <input
                type="text"
                name="lastName"
                placeholder={content[language].lastName}
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
            </motion.div>
            <motion.div className="flex flex-col sm:flex-row gap-4" variants={fadeInUpVariants}>
              <input
                type="email"
                name="email"
                placeholder={content[language].yourEmail}
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
              <input
                type="tel"
                name="phoneNumber"
                placeholder={content[language].phoneNumber}
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
            </motion.div>
            <motion.div className="flex flex-col sm:flex-row gap-4" variants={fadeInUpVariants}>
              <input
                type="text"
                name="companyName"
                placeholder={content[language].companyName}
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
              <input
                type="text"
                name="address"
                placeholder={content[language].addressPlaceholder}
                required
                className="w-full px-5 py-4 rounded-full border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300"
              />
            </motion.div>
            <motion.textarea
              name="message"
              placeholder={content[language].message}
              required
              className="w-full px-5 py-4 rounded-3xl border border-gray-300 focus:outline-none focus:border-red-500 focus:ring-2 focus:ring-red-200 transition duration-300 h-40 resize-y"
              variants={fadeInUpVariants}
            ></motion.textarea>
            <motion.button 
              type="submit" 
              className={`px-8 py-4 rounded-full text-white text-lg font-semibold shadow-md transition duration-300 ${
                submitStatus === 'sending' ? 'bg-orange-500 animate-pulse' :
                submitStatus === 'success' ? 'bg-green-500' :
                submitStatus === 'error' ? 'bg-red-500' :
                'bg-red-600 hover:bg-red-700'
              }`}
              disabled={submitStatus === 'sending'}
              variants={fadeInUpVariants}
            >
              {getButtonText()}
            </motion.button>
          </form>
        </motion.div>
      </div>
      <motion.div 
        className="mt-12 w-full h-[500px] rounded-2xl overflow-hidden shadow-lg"
        variants={fadeInUpVariants}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3109.5028029306114!2d19.818901416227434!3d41.32812867926939!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1350310350d8fc6f%3A0x76a4bf9b7cc6e259!2sSky%20Tower%20Tirana!5e0!3m2!1sen!2s!4v1693446255454!5m2!1sen!2s"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map Location"
        ></iframe>
      </motion.div>
    </motion.div>
  );
};

export default ContactForm;