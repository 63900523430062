import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../components/languageUtils';
import './MeetTheStaff.css';

const teamMembers = [
  { name: 'ELIRA KOKONA', roleEn: 'Executive Director', roleAl: 'Drejtor Ekzekutiv', image: '../../Elira.png', profileLinkEn: '/en/team/meet-the-staff/elira-kokona', profileLinkAl: '/sq/ekipi/njihuni-me-stafin/elira-kokona' },
  { name: 'JULIAN ADILI', roleEn: 'Chief Operations Officer', roleAl: 'Drejtor i Operacioneve', image: '../../Juli.png', profileLinkEn: '/en/team/meet-the-staff/julian-adili', profileLinkAl: '/sq/ekipi/njihuni-me-stafin/julian-adili' },
  { name: 'VALENTINA KAZANXHI', roleEn: 'Chief of Business Development', roleAl: 'Drejtor i Zhvillimit të Biznesit', image: '../../VALENTINA.png', profileLinkEn: '/en/team/meet-the-staff/valentina-kazanxhi', profileLinkAl: '/sq/ekipi/njihuni-me-stafin/valentina-kazanxhi'},
  { name: 'VACANT', roleEn: 'Chief Legal Officer', roleAl: 'Drejtor Ligjor', image: null, profileLinkEn: null, profileLinkAl: null },
  { name: 'GLEDIS SHEHU', roleEn: 'Chief Financial Officer', roleAl: 'Drejtor Financiar', image: '../../Gledi.png', profileLinkEn: '/en/team/meet-the-staff/gledis-shehu', profileLinkAl: '/sq/ekipi/njihuni-me-stafin/gledis-shehu'},
];

const TeamMemberCard = ({ name, roleEn, roleAl, image, profileLinkEn, profileLinkAl }) => {
  const { language } = useLanguage();
  const role = language === 'en' ? roleEn : roleAl;
  const profileLink = language === 'en' ? profileLinkEn : profileLinkAl;

  const ImageContent = () => (
    image ? (
      <img 
        loading="lazy" 
        src={image} 
        alt={name} 
        className="team-member-image" 
      />
    ) : (
      <div className="team-placeholder-image">
        👤
      </div>
    )
  );

  return (
    <div className="team-member-card">
      <div className="team-card-content">
        <div className="team-image-container">
          {profileLink ? (
            <Link to={profileLink} aria-label={`View ${name}'s profile`}>
              <ImageContent />
            </Link>
          ) : (
            <ImageContent />
          )}
        </div>
        <h3 className="team-member-name">
          {name === 'VACANT' ? (language === 'en' ? 'Position Available' : 'Pozicion i Lirë') : name}
        </h3>
        <p className="team-member-role">{role}</p>
      </div>
    </div>
  );
};

const TeamMembersShowcase = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      subheading: "OUR TEAM",
      heading: "MEET OUR DEDICATED TEAM"
    },
    sq: {
      subheading: "EKIPI YNË",
      heading: "NJIHUNI ME EKIPIN TONË TË DEDIKUAR"
    }
  };

  return (
    <div className="team-members-container">
      <h2 className="team-subheading">
        {content[language].subheading}
      </h2>
      <h1 className="team-heading">
        {content[language].heading}
      </h1>
      <div className="team-members">
        <div className="team-member-container">
          {teamMembers.map((member) => (
            <TeamMemberCard 
              key={member.name} 
              {...member} 
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamMembersShowcase;