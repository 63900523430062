import React from 'react';
import { useLanguage } from '../../components/languageUtils';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './ImpactSection.css';

const CustomArrowIcon = () => {
  return (
    <motion.svg
      className="impact-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      initial={{ opacity: 0, x: -10 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.3 }}
    >
      <path d="M4 4L20 12L4 20V4Z" />
    </motion.svg>
  );
};

const ImpactSection = () => {
  const { language, translations } = useLanguage();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this value to control when the animation triggers
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const content = {
    en: {
      title: "The Impact in Economic Development",
      impacts: [
        "AIC is a beacon institution in Albania's strategy to foster economic growth. By supporting investment projects that align with state policies, the corporation helps to create a favorable environment for economic development and innovation.",
        "The corporation focuses on optimizing the use of state assets to ensure they contribute effectively to the national economy. This includes transforming underutilized assets into productive investments that can generate economic value.",
        "AIC works to mobilize both public and private capital for investment projects. This approach helps to diversify funding sources and ensures that projects have the necessary financial backing to succeed.",
        "One of AIC's primary functions is to convert innovative ideas into funded projects. This involves comprehensive project review, structuring, and financing, which helps to bring concepts from the planning stage to implementation.",
        "AIC collaborates closely with the Albanian government to review and structure investment projects. This partnership ensures that projects are aligned with national development goals and are implemented efficiently."
      ]
    },
    sq: {
      title: " Ndikimi në zhvillimin ekonomik",
      impacts: [
        "AIC është një ndër institucionet kryesuese në strategjinë e Shqipërisë për të nxitur rritjen ekonomike. Duke mbështetur projekte investimi që përputhen me politikat shtetërore, korporata ndihmon në krijimin e një mjedisi të favorshëm për zhvillimin ekonomik dhe inovacionin. ",
        "Korporata fokusohet në optimizimin e përdorimit të aseteve shtetërore për të siguruar që ato të kontribuojnë efektivisht në ekonominë kombëtare. Kjo përfshin transformimin e aseteve të papërdorura në investime produktive që mund të gjenerojnë vlerë ekonomike.",
        "AIC punon për të mobilizuar kapitalin publik dhe privat për projekte investimi. Kjo qasje ndihmon në diversifikimin e burimeve të financimit dhe siguron që projektet të kenë mbështetjen e nevojshme financiare për të pasur sukses.",
        "Një nga funksionet kryesore të AIC-së është konvertimi i ideve inovative në projekte të financuara. Kjo përfshin rishikim, strukturim dhe financim gjithëpërfshirës të projektit, që ndihmon në sjelljen e koncepteve nga faza e planifikimit në zbatim.",
        "AIC bashkëpunon ngushtë me qeverinë shqiptare për të rishikuar dhe strukturuar projektet e investimit. Ky partneritet siguron që projektet të jenë në përputhje me objektivat kombëtare të zhvillimit dhe të zbatohen me efikasitet."
      ]
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.section 
      ref={ref}
      className="impact-section"
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <motion.h2 className="impact-title" variants={itemVariants}>{content[language].title}</motion.h2>
      <motion.ul className="impact-list" variants={containerVariants}>
        {content[language].impacts.map((impact, index) => (
          <motion.li key={index} className="impact-item" variants={itemVariants}>
            <CustomArrowIcon />
            {impact}
          </motion.li>
        ))}
      </motion.ul>
    </motion.section>
  );
};

export default ImpactSection;