import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import BlockContent from '@sanity/block-content-to-react';
import { ArrowLeft, ArrowRight, ExternalLink, Calendar, Globe, Tag } from 'lucide-react';

const NewsDetails = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [projects, setProjects] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await sanityClient.fetch(
          `*[_type in ["newsPost", "eventsPost", "noticesPost", "careerPost"] && slug.current == $slug][0]{
            _type,
            title,
            language,
            slug,
            publishDate,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            gallery[]{asset->{_id, url}},
            pdfFiles[]{
              asset->{url},
              customName
            },
            body,
            tags[]->{
              title
            },
            metaTitle,
            metaDescription,
            "next": *[_type == ^._type && language == ^.language && dateTime(publishDate) > dateTime(^.publishDate)] | order(publishDate asc) [0]{ title, slug },
            "previous": *[_type == ^._type && language == ^.language && dateTime(publishDate) < dateTime(^.publishDate)] | order(publishDate desc) [0]{ title, slug }
          }`,
          { slug }
        );

        if (!data) {
          throw new Error('Post not found');
        }

        setPost(data);
        setNextPost(data.next);
        setPrevPost(data.previous);
        window.scrollTo(0, 0);

        const projectsData = await sanityClient.fetch(
          `*[_type == "projectsPost" && language == $language]{
            title,
            slug,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body,
            category,
            projectDate
          }`,
          { language: data.language }
        );
        setProjects(projectsData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  const handleNavigation = (postSlug) => {
    const postType = post._type.replace('Post', '');
    navigate(`/${postType}/${postSlug.current}`);
    window.scrollTo(0, 0);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === post.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? post.gallery.length - 1 : prevIndex - 1
    );
  };

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === projects.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) =>
      prevIndex === 0 ? projects.length - 1 : prevIndex - 1
    );
  };

  const limitTitleLength = (title, limit = 20) => {
    return title.length > limit ? title.substring(0, limit) + '...' : title;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const getLanguageDisplay = (langCode) => {
    return langCode === 'sq' ? 'Shqip' : 'English';
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  if (isLoading) return <div className="text-center py-10">Loading...</div>;
  if (error) return <div className="text-center py-10 text-red-600">Error: {error}</div>;
  if (!post) return <div className="text-center py-10">No post found</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 flex flex-col lg:flex-row lg:justify-between">
      <div className="flex-1 max-w-3xl bg-white rounded-lg shadow-md p-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-5 pb-2 border-b-2 border-red-600">
          {post.title}
        </h1>
        <div className="flex items-center text-sm text-gray-600 mb-5">
          <Calendar size={16} className="mr-2" />
          <span className="mr-4">{formatDate(post.publishDate)}</span>
          <Globe size={16} className="mr-2" />
          <span>{getLanguageDisplay(post.language)}</span>
        </div>

        {post.mainImage?.asset?.url && (
          <img
            src={post.mainImage.asset.url}
            alt={post.mainImage.alt || post.title}
            className="w-full h-96 object-cover rounded-lg mb-8"
          />
        )}

        <div className="prose max-w-none mb-8">
          <BlockContent
            blocks={post.body}
            projectId="1ag8k8v1"
            dataset="production"
          />
        </div>

        {post.pdfFiles && post.pdfFiles.length > 0 && (
          <div className="bg-gray-100 border border-gray-300 rounded-lg p-6 mt-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Related Documents</h3>
            <div className="space-y-4">
              {post.pdfFiles.map((pdfFile, index) => (
                <div key={index} className="flex flex-col sm:flex-row gap-4 items-center">
                  <div className="bg-red-600 text-white py-2 px-4 rounded-md font-semibold">
                    <span>{pdfFile.customName || `PDF ${index + 1}`}</span>
                  </div>
                  <a 
                    href={pdfFile.asset.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded-md transition duration-300"
                  >
                    <ExternalLink size={20} className="mr-2" />
                    <span>View PDF</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {post.tags && post.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-8">
            <Tag size={20} className="text-red-600 mr-2" />
            {post.tags.map((tag, index) => (
              <span 
                key={index}
                className="bg-gray-200 text-red-600 px-3 py-1 rounded-full text-sm transition duration-300 hover:bg-red-600 hover:text-white cursor-default"
              >
                {tag.title}
              </span>
            ))}
          </div>
        )}

        {post.gallery && post.gallery.length > 0 && (
          <div className="mt-10 mb-10">
            <h3 className="text-2xl text-gray-800 mb-5">Gallery</h3>
            <div className="relative h-96 bg-gray-100 rounded-lg overflow-hidden">
              <button 
                onClick={prevImage} 
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-red-600 bg-opacity-70 hover:bg-opacity-90 text-white rounded-full p-2 transition duration-300 z-10"
              >
                <ArrowLeft size={20} />
              </button>
              <div className="w-full h-full flex justify-center items-center">
                <img
                  src={post.gallery[currentImageIndex].asset.url}
                  alt={`Gallery item ${currentImageIndex + 1}`}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <button 
                onClick={nextImage} 
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-red-600 bg-opacity-70 hover:bg-opacity-90 text-white rounded-full p-2 transition duration-300 z-10"
              >
                <ArrowRight size={20} />
              </button>
            </div>
            <p className="text-center mt-2 text-gray-600 italic">
              {`Image ${currentImageIndex + 1} of ${post.gallery.length}`}
            </p>
          </div>
        )}

        <div className="flex flex-col sm:flex-row justify-between gap-4 mt-10">
          {prevPost && (
            <button 
              onClick={() => handleNavigation(prevPost.slug)} 
              className="flex items-center justify-center sm:justify-start bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 transform hover:-translate-y-1"
            >
              <ArrowLeft size={20} className="mr-2" />
              <span>Previous: {limitTitleLength(prevPost.title)}</span>
            </button>
          )}
          {nextPost && (
            <button 
              onClick={() => handleNavigation(nextPost.slug)} 
              className="flex items-center justify-center sm:justify-end bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 transform hover:-translate-y-1"
            >
              <span>Next: {limitTitleLength(nextPost.title)}</span>
              <ArrowRight size={20} className="ml-2" />
            </button>
          )}
        </div>
      </div>

      <div className="w-full lg:w-80 mt-10 lg:mt-0 lg:ml-8">
        <div className="sticky top-5 bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl text-gray-800 mb-5 pb-2 border-b-2 border-red-600">Our Projects</h2>
          {projects.length > 0 ? (
            <div>
              {projects[currentProjectIndex].mainImage?.asset?.url && (
                <img
                  src={projects[currentProjectIndex].mainImage.asset.url}
                  alt={projects[currentProjectIndex].mainImage.alt || projects[currentProjectIndex].title}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
              )}
              <div>
                <h3 className="text-xl text-gray-800 mb-2">
                  {truncateText(projects[currentProjectIndex].title, 30)}
                </h3>
                <p className="text-sm text-gray-600 mb-2">
                  Category: {projects[currentProjectIndex].category}
                </p>
                {projects[currentProjectIndex].projectDate && (
                  <p className="text-sm text-gray-600 mb-2">
                    Date: {formatDate(projects[currentProjectIndex].projectDate)}
                  </p>
                )}
                <div className="text-sm text-gray-600 mb-4">
                  {truncateText(
                    projects[currentProjectIndex].body[0].children[0].text,
                    60
                  )}
                </div>
                <a 
                  href={`/projects/${projects[currentProjectIndex].slug.current}`}
                  className="inline-block bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm font-semibold transition duration-300"
                >
                  View Project
                </a>
              </div>
              <div className="flex justify-between mt-4">
                <button onClick={prevProject} className="text-red-600 hover:text-red-700 transition duration-300">
                  <ArrowLeft size={20} />
                </button>
                <button onClick={nextProject} className="text-red-600 hover:text-red-700 transition duration-300">
                  <ArrowRight size={20} />
                </button>
              </div>
            </div>
          ) : (
            <p className="text-gray-600">No projects available in {getLanguageDisplay(post.language)}.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetails;