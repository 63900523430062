import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import sanityClient from '../../sanity';
import { useLanguage } from '../../components/languageUtils';
import './ProjectsShowcase.css';

const ProjectsShowcase = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language, translations } = useLanguage();

  const pinBase64 = '/pin.png';
  const leftImageBase64 = '/Asset 3.png';
  const rightImageBase64 = '/Asset 3.png';

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const query = `*[
          _type == "projectsPost" && 
          language == $language && 
          (isProject == true || !defined(isProject))
        ] | order(projectDate desc) {
          title,
          "slug": slug.current,
          mainImage {
            asset-> {
              _id,
              url
            },
            alt
          },
          description
        }`;

        const result = await sanityClient.fetch(query, { language });
        console.log('Fetched projects:', result); // Debug log
        setProjects(result);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching projects:', err);
        setError("Failed to load projects.");
        setLoading(false);
      }
    };

    fetchProjects();
  }, [language]);

  if (loading) return <div className="text-center py-10">{translations?.common?.loading || 'Loading...'}</div>;
  if (error) return <div className="text-center py-10 text-red-600">{error}</div>;

  const getLocalizedProjectsPrefix = (language) => {
    return language === 'en' ? 'projects' : 'projekte';
  };

  return (
    <div className="projects-showcase-container">
      <div className="projects-showcase-header">
        <img src={leftImageBase64} alt="Left" className="projects-showcase-side-image projects-showcase-left-image" />
        <div className="projects-showcase-header-content">
          <h1>{language === 'en' ? 'PROJECTS' : 'PROJEKTE'}</h1>
          <p>
            {language === 'en' ? 
              "AIC aims to spur economic development through engaging in complex investment projects. It was established to act as an asset manager and project developer for under-utilized public sector assets in order to promote their monetization via private capital mobilization, thereby creating a positive impact on the domestic economy." :
              "AIC synon të nxisë zhvillimin ekonomik përmes angazhimit në projekte komplekse investimi. Ajo u themelua për të vepruar si menaxher i aseteve dhe zhvillues i projekteve për asetet e sektorit publik të nënpërdorura me qëllim promovimin e monetizimit të tyre përmes mobilizimit të kapitalit privat, duke krijuar kështu një ndikim pozitiv në ekonominë vendase."
            }
          </p>
        </div>
        <img src={rightImageBase64} alt="Right" className="projects-showcase-side-image projects-showcase-right-image" />
      </div>

      <div className="projects-showcase-card-container">
        {projects.length > 0 ? (
          projects.slice(0, 4).map((project) => {
            const projectsPrefix = getLocalizedProjectsPrefix(language);
            const permalink = `/${language}/${projectsPrefix}/${project.slug}`;

            return (
              <div key={project.slug} className="projects-showcase-card">
                <div className="projects-showcase-icon">
                  <img src={pinBase64} alt="Pin" />
                </div>
                <div className="projects-showcase-image">
                  {project.mainImage?.asset?.url && (
                    <img 
                      src={project.mainImage.asset.url} 
                      alt={project.mainImage.alt || project.title}
                      onError={(e) => {
                        console.error('Image failed to load:', project.mainImage.asset.url);
                        e.target.onerror = null;
                        e.target.src = 'https://via.placeholder.com/300x150?text=Image+Not+Available';
                      }}
                    />
                  )}
                </div>
                <div className="projects-showcase-card-content">
                  <h3 className="projects-showcase-title">
                    <Link to={permalink} className="project-link text-red-600 hover:text-red-700 transition duration-300">
                      {project.title}
                    </Link>
                  </h3>
                  <p>{project.description}</p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-gray-500 italic p-4">
            {language === 'en' ? (translations?.common?.noContent || 'No projects available') : 'Nuk ka projekte të disponueshme'}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectsShowcase;
