import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useLanguage } from '../../components/languageUtils';
import { useLocation } from 'react-router-dom';

const AnimatedMemberCard = ({ name, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      transition={{
        duration: 0.5,
        delay: index * 0.1
      }}
      whileHover={{ scale: 1.05, boxShadow: "0 10px 20px rgba(0,0,0,0.1)" }}
      className="bg-white rounded-lg shadow-md p-6 flex items-center justify-center"
    >
      <span className="text-xl font-semibold text-gray-800 text-center">{name}</span>
    </motion.div>
  );
};

const LazyImage = ({ src, alt, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [inView, controls]);

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={controls}
      transition={{ duration: 0.5 }}
      className="overflow-hidden"
    >
      <img
        src={src}
        alt={alt}
        className={className}
        loading="lazy"
      />
    </motion.div>
  );
};

const ResponsiveImagePage = () => {
  const { language } = useLanguage();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const titleControls = useAnimation();
  const imageControls = useAnimation();
  const boardTitleControls = useAnimation();

  const [titleRef, titleInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [imageRef, imageInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const [boardTitleRef, boardTitleInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timer);
  }, [location]);

  useEffect(() => {
    if (titleInView && !loading) {
      titleControls.start({ opacity: 1, y: 0 });
    }
  }, [titleInView, loading, titleControls]);

  useEffect(() => {
    if (imageInView && !loading) {
      imageControls.start({ opacity: 1 });
    }
  }, [imageInView, loading, imageControls]);

  useEffect(() => {
    if (boardTitleInView && !loading) {
      boardTitleControls.start({ opacity: 1, y: 0 });
    }
  }, [boardTitleInView, loading, boardTitleControls]);

  const content = {
    en: {
      title: "INSTITUTIONAL STRUCTURE",
      boardTitle: "Advisory Board Members",
      boardMembers: [
        "Mr. Edmir Nufi",
        "Mr. Ami Kozeli",
        "Ms. Sonila Qato",
        "Ms. Alketa Pernaska",
        "Mr. Valery Tsepkalo"
      ],
      imageAlt: "Institutional Structure"
    },
    sq: {
      title: "STRUKTURA INSTITUCIONALE",
      boardTitle: "Anëtarët e Bordit Këshillimor",
      boardMembers: [
        "Z. Edmir Nufi",
        "Z. Ami Kozeli",
        "Znj. Sonila Qato",
        "Znj. Alketa Pernaska",
        "Z. Valery Tsepkalo"
      ],
      imageAlt: "Struktura Institucionale"
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <motion.h1 
        ref={titleRef}
        initial={{ opacity: 0, y: -50 }}
        animate={titleControls}
        transition={{ duration: 0.5 }}
        className="text-4xl font-bold mb-8 text-center text-[#e41e26]"
      >
        {content[language].title}
      </motion.h1>
      <motion.div 
        ref={imageRef}
        initial={{ opacity: 0 }}
        animate={imageControls}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex justify-center mb-12"
      >
        <LazyImage
          src="../../organigrama.png"
          alt={content[language].imageAlt}
          className="w-full max-w-4xl h-auto object-cover rounded-lg shadow-lg"
        />
      </motion.div>
      <div className="max-w-3xl mx-auto">
        <motion.h2 
          ref={boardTitleRef}
          initial={{ opacity: 0, y: 50 }}
          animate={boardTitleControls}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="text-3xl font-semibold mb-6 text-center text-[#e41e26]"
        >
          {content[language].boardTitle}
        </motion.h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {content[language].boardMembers.map((member, index) => (
            <AnimatedMemberCard key={member} name={member} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ResponsiveImagePage;