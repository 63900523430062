import React, { createContext, useContext, useState } from 'react';

const translations = {
  en: {
    menu: {
      aboutUs: "ABOUT US",
      news: "NEWS",
      projects: "PROJECTS",
      openCalls: "OPEN CALLS",
      team: "TEAM",
      networking: "NETWORKING",
      contact: "CONTACT"
    },
    submenus: {
      aboutUs: {
        visionMission: "Our Vision & Mission",
        whyChooseAIC: "Why Choose AIC",
        lawsRegulations: "Laws and Regulations",
        transparencyProgram: "Transparency Program"
      },
      openCalls: {
        ongoing: "Ongoing",
        closedCalls: "Closed Calls"
      },
      team: {
        institutionalStructure: "Institutional Structure",
        meetTheStaff: "Meet the Staff",
        joinUs: "Join Us"
      },
      networking: {
        lineMinistersRelationship: "Relationship with Line Ministers",
        aidaRelationship: "Relationship with AIDA",
        adfRelationship: "Relationship with ADF"
      }
    },
    routes: {
      "about-us": "about-us",
      "vision-and-mission": "vision-and-mission",
      "why-choose-aic": "why-choose-aic",
      "law-and-regulations": "law-and-regulations",
      "transparency-program": "transparency-program", // Add this line
      "news": "news",
      "projects": "projects",
      "open-calls": "open-calls",
      "ongoing": "ongoing",
      "closed": "closed",
      "team": "team",
      "institutional-structure": "institutional-structure",
      "meet-the-staff": "meet-the-staff",
      "join-us": "join-us",
      "networking": "networking",
      "relationship-with-line-ministers": "relationship-with-line-ministers",
      "contact": "contact",
      "tags": "tags"
    },
    topInfo: {
      workHours: "Mon - Fri : 08:00 - 17:00",
      email: "info@corporation.al",
      address: "St. Ibrahim Rugova 5, Sky Tower 7/1, Tirana, Albania"
    },
    common: {
      investNow: "INVEST NOW!",
      search: "Search..."
    }
  },
  sq: {
    menu: {
      aboutUs: "RRETH NESH",
      news: "LAJME",
      projects: "PROJEKTE",
      openCalls: "THIRRJE TË HAPURA",
      team: "EKIPI",
      networking: "BASHKËPUNËTORËT",
      contact: "KONTAKT"
    },
    submenus: {
      aboutUs: {
        visionMission: "Vizioni dhe Misioni Ynë",
        whyChooseAIC: "Pse të Zgjidhni AIC",
        lawsRegulations: "Ligjet dhe Rregulloret",
        transparencyProgram: "Programi i Transparencës"
      },
      openCalls: {
        ongoing: "Në Vazhdim",
        closedCalls: "Thirrje të Mbyllura"
      },
      team: {
        institutionalStructure: "Struktura Institucionale",
        meetTheStaff: "Njihuni me Stafin",
        joinUs: "Bashkohuni me Ne"
      },
      networking: {
        lineMinistersRelationship: "Marrëdhënia me Ministritë e Linjës",
        aidaRelationship: "Marrëdhënia me AIDA",
        adfRelationship: "Marrëdhënia me ADF"
      }
    },
    routes: {
      "about-us": "rreth-nesh",
      "vision-and-mission": "vizioni-dhe-misioni",
      "why-choose-aic": "pse-te-zgjidhni-aic",
      "law-and-regulations": "ligjet-dhe-rregulloret",
      "transparency-program": "programi-i-transparences", // Add this line
      "news": "lajme",
      "projects": "projekte",
      "open-calls": "thirrje-te-hapura",
      "ongoing": "ne-vazhdim",
      "closed": "te-mbyllura",
      "team": "ekipi",
      "institutional-structure": "struktura-institucionale",
      "meet-the-staff": "njihuni-me-stafin",
      "join-us": "bashkohuni-me-ne",
      "networking": "bashkëpunëtorët",
      "relationship-with-line-ministers": "marredhenia-me-ministrite-e-linjes",
      "contact": "kontakt",
      "tags": "etiketat"
    },
    topInfo: {
      workHours: "E Hënë - E Premte : 08:00 - 17:00",
      email: "info@corporation.al",
      address: "Rr. Ibrahim Rugova 5, Sky Tower 7/1, Tiranë, Shqipëri"
    },
    common: {
      investNow: "INVESTO TANI!",
      search: "Kërko..."
    }
  }
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');

  const changeLanguage = (lang) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);

export const translateRoute = (route, fromLang, toLang) => {
  if (!route || typeof route !== 'string') {
    console.warn('Invalid route provided to translateRoute:', route);
    return '';
  }

  const segments = route.split('/').filter(Boolean);
  return segments.map(segment => 
    translations[toLang]?.routes[segment] || segment
  ).join('/');
};

export const getRouteLanguage = (pathname) => {
  const lang = pathname.split('/')[1];
  return ['en', 'sq'].includes(lang) ? lang : 'en';
};