// src/App.js
import React from 'react';
import MissionSection from './MissoinSection';
import PinAboutUs from './PinAboutUs';
import ImpactSection from './ImpactSection';

import './About.css';


function About() {
  return (
    <div className="App">
    <MissionSection/>
    <PinAboutUs/>
    <ImpactSection/>
    </div>
  );
}

export default About;