import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import sanityClient from '../../sanity';
import { useLanguage } from '../../components/languageUtils';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './AICRelationshipPage.css';

const AICRelationshipPage = () => {
  const location = useLocation();
  const [activeButton, setActiveButton] = useState(location.pathname);
  const [projects, setProjects] = useState([]);
  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);
  const { language } = useLanguage();
  const [loading, setLoading] = useState(true);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timer);
  }, [location]);

  const content = {
    en: {
      pageTitle: "RELATIONSHIP WITH LINE MINISTRIES",
      imageAlt: "AIC meeting with ministers",
      paragraph1: "Ministries serve as the central hubs where public policy is formulated, debated, and refined to address the diverse needs and priorities of the populace. These institutions play a critical role in shaping the direction of governmental action and ensuring that policies are implemented effectively. Within this context, the AIC law delineates a clear and stringent process for the involvement of the Agency for Infrastructure and Construction (AIC) in projects that involve public assets.",
      paragraph2: "According to the law, it requires a formal decision by the Council of Ministers to authorize the AIC to undertake any such project. This requirement underscores the importance of high-level oversight and accountability, ensuring that public resources are managed responsibly and transparently.",
      paragraph3: "AIC functions as a strategic tool and operational agent available to line ministries. This relationship enables ministries to leverage AIC's specialized expertise, resources, and capabilities to bring their projects to fruition. When a ministry identifies a project that aligns with its strategic vision and objectives, it can choose to employ AIC as a means to implement and actualize the initiative.",
      paragraph4: "This collaborative mechanism facilitates the translation of policy into tangible outcomes, allowing ministries to execute projects that are integral to their mission and contribute to the broader goals of public service and national development.",
      quickLinks: "QUICK LINKS",
      ourProjects: "OUR PROJECTS",
      viewProject: "View Project",
      loadingProjects: "Loading projects..."
    },
    sq: {
      pageTitle: "MARRËDHËNIA ME MINISTRITË E LINJËS",
      imageAlt: "Takimi i AIC me ministrat",
      paragraph1: "Ministritë shërbejnë si qendrat kryesore ku politikat publike formulohen, debatohen dhe rafinohen për të adresuar nevojat dhe prioritetet e ndryshme të popullatës. Këto institucione luajnë një rol kritik në formësimin e drejtimit të veprimit qeveritar dhe sigurimin që politikat të zbatohen në mënyrë efektive. Në këtë kontekst, ligji i AIC përcakton një proces të qartë dhe të rreptë për përfshirjen e Agjencisë për Infrastrukturë dhe Ndërtim (AIC) në projekte që përfshijnë asetet publike.",
      paragraph2: "Sipas ligjit, kërkohet një vendim formal nga Këshilli i Ministrave për të autorizuar AIC-në të ndërmarrë çdo projekt të tillë. Kjo kërkesë nënvizon rëndësinë e mbikëqyrjes dhe llogaridhënies së nivelit të lartë, duke siguruar që burimet publike të menaxhohen në mënyrë të përgjegjshme dhe transparente.",
      paragraph3: "AIC funksionon si një mjet strategjik dhe agjent operacional në dispozicion të ministrive të linjës. Kjo marrëdhënie u mundëson ministrive të shfrytëzojnë ekspertizën e specializuar, burimet dhe kapacitetet e AIC-së për të realizuar projektet e tyre. Kur një ministri identifikon një projekt që përputhet me vizionin dhe objektivat e saj strategjike, ajo mund të zgjedhë të përdorë AIC-në si mjet për të zbatuar dhe aktualizuar iniciativën.",
      paragraph4: "Ky mekanizëm bashkëpunues lehtëson shndërrimin e politikës në rezultate të prekshme, duke u lejuar ministrive të ekzekutojnë projekte që janë thelbësore për misionin e tyre dhe kontribuojnë në qëllimet më të gjera të shërbimit publik dhe zhvillimit kombëtar.",
      quickLinks: "LIDHJE TË SHPEJTA",
      ourProjects: "PROJEKTET TONA",
      viewProject: "Shiko Projektin",
      loadingProjects: "Duke ngarkuar projektet..."
    }
  };

  const sidebarButtons = [
    { name: content[language].pageTitle, path: '/networking/relationship-with-line-ministers' },
    { name: 'RELATIONSHIP WITH AIDA', path: '/networking/relationship-with-aida' },
    { name: 'RELATIONSHIP WITH ADF', path: '/networking/relationship-with-adf' },
  ];

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectsPost"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          excerpt
        }`
      )
      .then((data) => setProjects(data))
      .catch(console.error);
  }, []);

  const nextProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
  };

  const prevProject = () => {
    setCurrentProjectIndex((prevIndex) => (prevIndex - 1 + projects.length) % projects.length);
  };

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <motion.div 
      className="relationshipwithministers-page-container"
      ref={ref}
      initial="hidden"
      animate={inView && !loading ? "visible" : "hidden"}
      variants={{
        hidden: {},
        visible: {
          transition: {
            staggerChildren: 0.2,
          },
        },
      }}
    >
      <main className="relationshipwithministers-main-content">
        <div className="relationshipwithministers-content-wrapper">
          <motion.div className="relationshipwithministers-content-area" variants={fadeInUpVariants}>
            <h2 className="relationshipwithministers-content-title">{content[language].pageTitle}</h2>
            
            <div className="relationshipwithministers-main-image">
              <img src="../ministers.jpg" alt={content[language].imageAlt} />
            </div>
            
            <div className="relationshipwithministers-content-text">
              <p>{content[language].paragraph1}</p>
              <p>{content[language].paragraph2}</p>
              <p>{content[language].paragraph3}</p>
              <p>{content[language].paragraph4}</p>
            </div>
          </motion.div>
          
          <motion.aside className="relationshipwithministers-sidebar" variants={fadeInUpVariants}>
            <nav className="relationshipwithministers-sidebar-nav">
              <h3 className="relationshipwithministers-sidebar-title">{content[language].quickLinks}</h3>
              <ul className="relationshipwithministers-sidebar-buttons">
                {sidebarButtons.map((button) => (
                  <li key={button.path}>
                    <Link 
                      to={button.path}
                      className={`relationshipwithministers-sidebar-button ${activeButton === button.path ? 'active' : ''}`}
                      onClick={() => setActiveButton(button.path)}
                    >
                      {button.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            
            <div className="relationshipwithministers-project-section">
              <h3 className="relationshipwithministers-project-title">{content[language].ourProjects}</h3>
              {projects.length > 0 ? (
                <div className="relationshipwithministers-project-carousel">
                  <div className="relationshipwithministers-project-card">
                    {projects[currentProjectIndex].mainImage?.asset?.url && (
                      <img
                        src={projects[currentProjectIndex].mainImage.asset.url}
                        alt={projects[currentProjectIndex].mainImage.alt || projects[currentProjectIndex].title}
                        className="relationshipwithministers-project-image"
                      />
                    )}
                    <div className="relationshipwithministers-project-card-content">
                      <h4>{projects[currentProjectIndex].title}</h4>
                      <p>{projects[currentProjectIndex].excerpt}</p>
                      <Link to={`/projects/${projects[currentProjectIndex].slug.current}`} className="relationshipwithministers-read-more">
                        {content[language].viewProject}
                      </Link>
                    </div>
                  </div>
                  <div className="relationshipwithministers-project-navigation">
                    <ChevronLeft className="relationshipwithministers-nav-arrow" onClick={prevProject} />
                    <ChevronRight className="relationshipwithministers-nav-arrow" onClick={nextProject} />
                  </div>
                </div>
              ) : (
                <p>{content[language].loadingProjects}</p>
              )}
            </div>
          </motion.aside>
        </div>
      </main>
    </motion.div>
  );
};

export default AICRelationshipPage;