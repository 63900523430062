import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import sanityClient from '../../sanity';  // Import your Sanity client
import './TagPages.css';  // Import any styles

const TagPage = () => {
  const { slug } = useParams();  // Get the tag slug from the URL
  const [newsPosts, setNewsPosts] = useState([]);

  useEffect(() => {
    // Fetch all news posts that have the clicked tag
    sanityClient
      .fetch(
        `*[_type == "newsPost" && "${slug}" in tags[]->slug.current]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          body,
          "excerpt": pt::text(body)[0...200]  // Fetch first 200 characters of the body as excerpt
        }`
      )
      .then((data) => setNewsPosts(data))
      .catch(console.error);
  }, [slug]);

  return (
    <div className="tag-page">
      <h1 className="tag-title">News tagged with #{slug}</h1>
      <div className="news-grid">
        {newsPosts.length > 0 ? (
          newsPosts.map((post) => (
            <div key={post.slug.current} className="news-item">
              <img
                src={post.mainImage.asset.url}
                alt={post.mainImage.alt}
                className="news-image"
              />
              <div className="news-content">
                <h2 className="news-item-title">{post.title}</h2>
                <p className="news-excerpt">{post.excerpt}...</p>
                <a href={`/news/${post.slug.current}`} className="news-read-more">
                  Read More
                </a>
              </div>
            </div>
          ))
        ) : (
          <p>No news found for this tag.</p>
        )}
      </div>
    </div>
  );
};

export default TagPage;
