import React from 'react';
import { useLanguage } from '../../components/languageUtils';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './PinAboutUs.css';

const PinAboutUs = () => {
  const { language } = useLanguage();
  const leftImageBase64 = '../../Asset 3.png'; // Replace with your left image base64 string
  const rightImageBase64 = '../../Asset 3.png'; // Replace with your right image base64 string

  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this value to control when the animation triggers
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const content = {
    en: {
      subtitle: "OUR VISION",
      title: "INNOVATIVE INVESTING ALBANIAN INVESTMENT CORPORATION",
      paragraphs: [
        "AIC envisions promoting sustainable economic growth by supporting investment projects across various sectors, including scientific and technological parks, fair industries, tourism, and real estate industry. The corporation aims to transform ideas into funded projects and optimize state assets for greater efficiency.",
        "By leveraging both public and private capital, AIC seeks to develop and finance projects that contribute to economic development and adhere to state policies. The corporation is committed to aiding the government in reviewing and structuring projects to ensure their success and alignment with national goals."
      ]
    },
    sq: {
      subtitle: "VIZIONI",
      title: "AIC: PROMOVIMI I RRITJES SË QËNDRUESHME EKONOMIKE DHE MBËSHTETJA E PROJEKTEVE STRATEGJIKE",
      paragraphs: [
        "AIC parashikon promovimin e rritjes së qëndrueshme ekonomike duke mbështetur projekte investimi në sektorë të ndryshëm, duke përfshirë parqet shkencore dhe teknologjike, industritë e panairit, turizmin dhe industrinë e pasurive të paluajtshme. Korporata synon të transformojë idetë në projekte të financuara dhe të optimizojë asetet shtetërore për efikasitet më të madh. Duke shfrytëzuar kapitalin publik dhe privat,",
        "AIC kërkon të zhvillojë dhe financojë projekte që kontribuojnë në zhvillimin ekonomik dhe që u përmbahen politikave shtetërore. Korporata është e përkushtuar të ndihmojë qeverinë në rishikimin dhe strukturimin e projekteve për të siguruar suksesin e tyre dhe përafrimin me qëllimet kombëtare. "
      ]
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  const imageVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { 
      opacity: 1, 
      scale: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      ref={ref}
      className="PinAboutUs"
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <motion.img 
        src={leftImageBase64} 
        alt="Left side view" 
        className="PinAboutUs-side-image PinAboutUs-left-image" 
        variants={imageVariants}
      />
      <motion.div className="PinAboutUs-content" variants={itemVariants}>
        <motion.h2 variants={itemVariants}>{content[language].subtitle}</motion.h2>
        <motion.h1 variants={itemVariants}>{content[language].title}</motion.h1>
        {content[language].paragraphs.map((paragraph, index) => (
          <motion.p key={index} variants={itemVariants}>{paragraph}</motion.p>
        ))}
      </motion.div>
      <motion.img 
        src={rightImageBase64} 
        alt="Right side view" 
        className="PinAboutUs-side-image PinAboutUs-right-image" 
        variants={imageVariants}
      />
    </motion.div>
  );
};

export default PinAboutUs;