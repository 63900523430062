import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '../../components/languageUtils';
import sanityClient from '../../sanity';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const getNewsPath = (lang) => {
  return lang === 'sq' ? 'lajme' : 'news';
};

const NewsCard = ({ imageUrl, title, metaDescription, slug, publishDate, language }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    const newsPath = getNewsPath(language);
    navigate(`/${language}/${newsPath}/${slug}`);
  };

  return (
    <motion.div 
      className="flex flex-col bg-white rounded-3xl overflow-hidden shadow-lg w-full cursor-pointer h-full"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleClick}
    >
      <div className="relative h-64">
        {imageUrl ? (
          <img src={imageUrl} alt={title} className="w-full h-full object-cover object-center" />
        ) : (
          <div className="w-full h-full bg-gray-200 flex items-center justify-center text-gray-500">
            {language === 'en' ? 'No Image Available' : 'Nuk ka imazh të disponueshëm'}
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between p-6 flex-grow">
        <div>
          <p className="text-red-600 text-lg mb-2">
            {language === 'en' ? 'News' : 'Lajme'}
          </p>
          <h3 className="font-bold text-xl mb-3 line-clamp-2">{title}</h3>
          <p className="text-gray-600 text-sm mb-2">
            {new Date(publishDate).toLocaleDateString(language === 'en' ? 'en-GB' : 'sq-AL', { 
              day: 'numeric', 
              month: 'long', 
              year: 'numeric' 
            })}
          </p>
          <p className="text-gray-600 text-sm mb-4 line-clamp-3">{metaDescription}</p>
        </div>
        <p className="text-red-600 text-lg mt-auto">
          {language === 'en' ? 'Read More' : 'Lexo më shumë'}
        </p>
      </div>
    </motion.div>
  );
};

const News = () => {
  const [newsPosts, setNewsPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(null);
  const { language } = useLanguage();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    setLoading(true);
    const fetchNewsAndSettings = async () => {
      try {
        const [newsData, settingsData] = await Promise.all([
          sanityClient.fetch(
            `*[_type == "newsPost" && language == $language] | order(publishDate desc)[0...4]{
              title,
              slug,
              mainImage{
                asset->{
                  _id,
                  url
                },
                alt
              },
              metaDescription,
              publishDate
            }`,
            { language }
          ),
          sanityClient.fetch(
            `*[_type == "siteSettings" && language == $language][0]{
              newsBackgroundImage {
                asset->{
                  _id,
                  url
                }
              },
              newsTitle,
              newsSubtitle
            }`,
            { language }
          )
        ]);

        setNewsPosts(newsData);
        setSettings(settingsData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching news posts and settings:', err);
        setError(language === 'en' ? 'Failed to load news.' : 'Dështoi në ngarkimin e lajmeve.');
        setLoading(false);
      }
    };

    fetchNewsAndSettings();
  }, [language]);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  if (loading) return (
    <div className="text-center text-xl text-white bg-black bg-opacity-50 p-4 rounded-lg my-8">
      {language === 'en' ? 'Loading news...' : 'Duke ngarkuar lajmet...'}
    </div>
  );
  
  if (error) return (
    <div className="text-center text-xl text-white bg-black bg-opacity-50 p-4 rounded-lg my-8">
      {error}
    </div>
  );

  return (
    <motion.div 
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="bg-gray-100 py-8 md:py-16 bg-cover bg-center relative" 
      style={{backgroundImage: `url('${settings?.newsBackgroundImage?.asset?.url || '../news.jpg'}')`}}
    >
      <div className="absolute inset-0 bg-black bg-opacity-70"></div>
      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div className="text-center mb-12" variants={itemVariants}>
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-2">
            {settings?.newsTitle || (language === 'en' ? 'NEWS FROM ALBANIA INVESTMENT CORPORATION' : 'LAJME NGA KORPORATA E INVESTIMEVE SHQIPTARE')}
          </h2>
          <p className="text-lg text-white mb-4">
            {settings?.newsSubtitle || (language === 'en' ? 'Making Headlines in the Investment World' : 'Duke bërë Tituj në Botën e Investimeve')}
          </p>
          <motion.div 
            className="w-16 h-1 bg-red-600 mx-auto mt-4"
            initial={{ width: 0 }}
            animate={{ width: '4rem' }}
            transition={{ duration: 0.8, ease: 'easeInOut' }}
          ></motion.div>
        </motion.div>

        {newsPosts.length > 0 ? (
          <motion.div 
            className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-6xl mx-auto"
            variants={containerVariants}
          >
            {newsPosts.map((post) => (
              <motion.div key={post.slug.current} variants={itemVariants} className="h-full">
                <NewsCard
                  imageUrl={post.mainImage?.asset?.url}
                  title={post.title}
                  metaDescription={post.metaDescription}
                  slug={post.slug.current}
                  publishDate={post.publishDate}
                  language={language}
                />
              </motion.div>
            ))}
          </motion.div>
        ) : (
          <motion.div 
            className="flex justify-center items-center min-h-[100px] text-center text-xl text-red-600 bg-gray-100 bg-opacity-80 rounded-xl shadow-lg my-5 w-full max-w-5xl mx-auto p-6"
            variants={itemVariants}
          >
            <p>
              {language === 'en' 
                ? 'No news articles available at the moment. Check back soon for updates!' 
                : 'Nuk ka artikuj lajmesh të disponueshëm për momentin. Kontrolloni së shpejti për përditësime!'}
            </p>
          </motion.div>
        )}

        <motion.div variants={itemVariants}>
          <Link 
            to={`/${language}/${getNewsPath(language)}`} 
            className="block mx-auto mt-12 px-6 py-3 bg-red-600 text-white text-lg font-semibold rounded-lg hover:bg-red-700 transition duration-300 ease-in-out text-center max-w-[200px]"
          >
            {language === 'en' ? 'SEE MORE' : 'SHIKO MË SHUMË'}
          </Link>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default News;