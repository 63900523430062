import React, { useState, useContext, createContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from './languageUtils';
import sanityClient from '../sanity';

export const GlobalLanguageContext = createContext();

export const GlobalLanguageProvider = ({ children }) => {
  const [globalLanguage, setGlobalLanguage] = useState('en');

  return (
    <GlobalLanguageContext.Provider value={{ globalLanguage, setGlobalLanguage }}>
      {children}
    </GlobalLanguageContext.Provider>
  );
};

const LanguageSwitcher = () => {
  const { language, changeLanguage, translations } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(false);
  const { globalLanguage, setGlobalLanguage } = useContext(GlobalLanguageContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchSanityTranslation = async (slug, currentLang, contentType) => {
    try { 
      const post = await sanityClient.fetch(
        `*[slug.current == $slug && language == $currentLang && _type == $contentType][0]{
          "translationRef": translationRef->{
            "slug": slug.current, 
            language,
            "permalink": permalink.current
          }
        }`,
        { slug, currentLang, contentType }
      );
      return post?.translationRef;
    } catch (err) {
      console.error('Error fetching Sanity translation:', err);
      return null;
    }
  };

  const translateSegment = (segment, fromLang, toLang) => {
    const fromRoutes = translations[fromLang].routes;
    const toRoutes = translations[toLang].routes;
    const key = Object.keys(fromRoutes).find(key => fromRoutes[key] === segment);
    return key ? toRoutes[key] : segment;
  };

  const getContentType = (segment) => {
    if (['news', 'lajme'].includes(segment)) return 'newsPost';
    if (['projects', 'projekte'].includes(segment)) return 'projectsPost';
    return null;
  };

  const switchLanguage = async (newLang) => {
    if (isFetching || globalLanguage === newLang) return;

    setIsFetching(true);
    console.log('Switching language to:', newLang);
    console.log('Current path:', location.pathname);

    const currentPath = location.pathname.substring(3);
    const pathSegments = currentPath.split('/').filter(Boolean);
    console.log('Path segments:', pathSegments);

    let translatedSegments;
    const contentType = getContentType(pathSegments[0]);

    if (contentType) {
      const slug = pathSegments[1];
      const translationRef = await fetchSanityTranslation(slug, language, contentType);
      
      if (translationRef && translationRef.language === newLang) {
        const newContentType = translateSegment(pathSegments[0], language, newLang);
        translatedSegments = [newContentType, translationRef.permalink || translationRef.slug];
      } else {
        const newContentType = translateSegment(pathSegments[0], language, newLang);
        translatedSegments = [newContentType, slug];
      }
    } else {
      translatedSegments = pathSegments.map(segment => 
        translateSegment(segment, language, newLang)
      );
    }

    console.log('Translated segments:', translatedSegments);
    const newPath = `/${newLang}/${translatedSegments.join('/')}`;
    console.log('New path:', newPath);

    changeLanguage(newLang);
    setGlobalLanguage(newLang);
    navigate(newPath, { replace: true });
    setIsFetching(false);
  };

  const buttonClass = (lang) => {
    const baseClass = isFetching ? 'opacity-50 cursor-not-allowed' : '';
    const mobileClass = `px-3 py-1 bg-red-600 text-white rounded-md ${baseClass} ${
      globalLanguage === lang ? 'font-bold underline' : 'font-normal'
    }`;
    const desktopClass = `px-1 py-0.5 ${
      globalLanguage === lang ? 'text-red-600 font-bold' : 'text-white'
    } ${baseClass}`;

    return isMobile ? mobileClass : desktopClass;
  };

  return (
    <div className="flex items-center space-x-2">
      <button 
        onClick={() => switchLanguage('en')} 
        className={buttonClass('en')}
        disabled={isFetching}
      >
        EN
      </button>
      <button 
        onClick={() => switchLanguage('sq')} 
        className={buttonClass('sq')}
        disabled={isFetching}
      >
        SQ
      </button>
    </div>
  );
};

export default LanguageSwitcher;