import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import { useLanguage } from '../../components/languageUtils';

const ExploreOurProjects = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVisible, setCursorVisible] = useState(false);
  const containerRef = useRef(null);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e) => {
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setMousePosition({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
    }
    setCursorVisible(true);
  };

  const handleMouseLeave = () => {
    setCursorVisible(false);
  };

  useEffect(() => {
    setLoading(true);
    sanityClient
      .fetch(
        `*[_type == "projectsPost" && language == $language && (isProject == true || !defined(isProject))] | order(projectDate desc) {
          _id,
          title,
          slug,
          mainImage {
            asset->{
              _id,
              url
            },
            alt
          },
          metaTitle,
          category,
          projectDate,
          isProject
        }`,
        { language }
      )
      .then((data) => {
        // Filter out any posts where isProject might be explicitly false
        const projectsOnly = data.filter(post => post.isProject !== false);
        
        if (projectsOnly.length === 0) {
          setError('No projects found for the current language.');
        } else {
          setProjects(projectsOnly);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error('Sanity fetch error:', err);
        setError('Failed to load projects.');
        setLoading(false);
      });
  }, [language]);

  const getProjectsPath = (lang) => {
    return lang === 'sq' ? 'projekte' : 'projects';
  };

  const handleProjectClick = (slug) => {
    const projectsPath = getProjectsPath(language);
    navigate(`/${language}/${projectsPath}/${slug.current}`);
  };

  if (loading) return (
    <div className="h-screen flex items-center justify-center bg-white">
      <div className="w-16 h-16 border-4 border-red-600 border-t-transparent rounded-full animate-spin"></div>
    </div>
  );
  
  if (error) return (
    <div className="h-screen flex items-center justify-center bg-white text-red-600 text-xl font-bold">
      {error}
    </div>
  );

  return (
    <div 
      ref={containerRef}
      className="relative w-full min-h-screen bg-white overflow-hidden"
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {/* Background Elements */}
      {cursorVisible && (
        <>
          <div
            className="pointer-events-none absolute -translate-x-1/2 -translate-y-1/2 h-[600px] w-[600px] bg-gradient-to-r from-red-500/10 to-red-600/10 blur-3xl rounded-full transition-transform duration-200"
            style={{
              left: mousePosition.x,
              top: mousePosition.y,
              transform: `translate(-50%, -50%) scale(${isHovering ? 1.2 : 1})`,
            }}
          />
          <div
            className="pointer-events-none absolute -translate-x-1/2 -translate-y-1/2 h-[300px] w-[300px] bg-gradient-to-r from-red-400/20 to-red-500/20 blur-2xl rounded-full transition-transform duration-150"
            style={{
              left: mousePosition.x,
              top: mousePosition.y,
              transform: `translate(-50%, -50%) scale(${isHovering ? 0.8 : 1})`,
            }}
          />
        </>
      )}

      {/* Rest of your UI components... */}
      <img 
        src="/Asset 2.png" 
        alt="" 
        className="absolute bottom-0 left-0 w-1/4 h-auto object-cover opacity-70 transform -translate-x-1/2 transition-transform duration-500 hover:scale-110 animate-float-3d" 
      />
      <img 
        src="/Asset 2.png" 
        alt="" 
        className="absolute bottom-0 right-0 w-1/4 h-auto object-cover opacity-70 transform translate-x-1/2 scale-x-[-1] transition-transform duration-500 hover:scale-110 animate-float-3d"
        style={{ animationDelay: '0.5s' }}
      />

      {/* Interactive grid background */}
      <div className="absolute inset-0">
        <div 
          className="absolute inset-0"
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(220,38,38,0.1) 1px, transparent 1px)',
            backgroundSize: '40px 40px',
            backgroundPosition: `${mousePosition.x * 0.02}px ${mousePosition.y * 0.02}px`,
            transition: 'background-position 0.15s ease-out',
          }}
        />
        <div 
          className="absolute inset-0"
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(220,38,38,0.05) 1.5px, transparent 1.5px)',
            backgroundSize: '25px 25px',
            backgroundPosition: `${mousePosition.x * 0.01}px ${mousePosition.y * 0.01}px`,
            transition: 'background-position 0.25s ease-out',
          }}
        />
      </div>

      <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="text-center mb-16 relative">
          <div className="inline-block relative">
            <h2 className="text-red-600 text-lg font-medium uppercase tracking-wider mb-2 relative z-10">
              <span className="relative">
                {language === 'en' ? 'OUR PROJECTS' : 'PROJEKTET TONA'}
                <div className="absolute -bottom-1 left-0 w-full h-0.5 bg-red-600 transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-300"></div>
              </span>
            </h2>
            <h2 className="text-5xl font-bold text-gray-900 uppercase tracking-wide relative z-10">
              {language === 'en' ? 'DISCOVER OUR SECTORS' : 'ZBULONI SEKTORËT TANË'}
            </h2>
            <div className="absolute -inset-x-20 -inset-y-10 bg-red-50 opacity-50 blur-3xl -z-10"></div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
          {projects.map((project) => (
            <div 
              key={project._id}
              className="group relative h-80 rounded-xl overflow-hidden cursor-pointer transition-all duration-500 hover:scale-105 hover:-rotate-1"
              onClick={() => handleProjectClick(project.slug)}
              onMouseEnter={() => setIsHovering(true)}
              onMouseLeave={() => setIsHovering(false)}
            >
              <img 
                src={project.mainImage?.asset?.url} 
                alt={project.mainImage?.alt || project.title} 
                className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
              />
              
              <div className="absolute inset-0 bg-gradient-to-t from-black/90 via-black/50 to-transparent">
                <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                  <div className="absolute inset-0 bg-gradient-to-r from-red-500/30 to-red-600/30" />
                </div>
              </div>

              <div className="absolute bottom-0 left-0 right-0 p-6 transform transition-transform duration-500">
                <h3 className="text-white text-2xl font-bold mb-2 transform transition-all duration-500 group-hover:translate-x-2">
                  {project.metaTitle || project.title}
                </h3>
                <div className="h-0.5 w-16 bg-red-500 transform origin-left scale-x-0 transition-transform duration-500 group-hover:scale-x-100" />
              </div>

              <div className="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 pointer-events-none transition-transform duration-500 group-hover:rotate-12">
                <img src="/Asset 1.png" alt="" className="opacity-80" />
              </div>
              <div className="absolute left-0 bottom-0 transform -translate-x-1/2 translate-y-1/2 pointer-events-none transition-transform duration-500 group-hover:-rotate-12">
                <img src="/Asset 1.png" alt="" className="opacity-80" />
              </div>
            </div>
          ))}
        </div>

        <div className="text-center">
          <Link 
            to={`/${language}/${getProjectsPath(language)}`}
            className="relative inline-block px-8 py-4 bg-red-600 text-white font-bold uppercase rounded-xl transform transition-all duration-300 hover:scale-105 hover:bg-red-700 group"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <span className="relative z-10">
              {language === 'en' ? 'VIEW PROJECTS' : 'SHIKO PROJEKTET'}
            </span>
            <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-red-500 to-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 -z-10"></div>
            <div className="absolute -inset-1 rounded-xl bg-gradient-to-r from-red-500 to-red-600 opacity-0 blur group-hover:opacity-30 transition-opacity duration-300 -z-10"></div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ExploreOurProjects;
