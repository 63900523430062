import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import './Redbox.css';

const LawSlider = () => {
  const { t } = useTranslation('homePage');
  const [currentSlide, setCurrentSlide] = useState(0);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const slides = t('lawSlider.slides', { returnObjects: true });

  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 700);
    return () => clearTimeout(timer);
  }, [location]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" },
    },
  };

  return (
    <motion.div
      className="law-slider"
      ref={ref}
      initial="hidden"
      animate={inView && !loading ? "visible" : "hidden"}
      variants={containerVariants}
    >
      <motion.div className="law-slider__content" variants={itemVariants}>
        <motion.div className="law-slider__text" variants={itemVariants}>
          <motion.h2 className="law-slider__title" variants={itemVariants}>
            {slides[currentSlide].title}
          </motion.h2>
          <motion.p className="law-slider__description" variants={itemVariants}>
            {slides[currentSlide].description}
          </motion.p>
          {slides[currentSlide].pdfUrl && (
            <motion.a 
              href={slides[currentSlide].pdfUrl} 
              download 
              className="law-slider__download-button"
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              {t('common.viewPDF')}
            </motion.a>
          )}
        </motion.div>
        <motion.div className="law-slider__image-container" variants={itemVariants}>
          <motion.img 
            className="law-slider__image" 
            src={slides[currentSlide].image} 
            alt={slides[currentSlide].title}
          />
        </motion.div>
      </motion.div>
      <motion.div className="law-slider__nav" variants={itemVariants}>
        <motion.button 
          onClick={prevSlide} 
          className="law-slider__nav-button law-slider__nav-button--prev"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          &#8249;
        </motion.button>
        <motion.button 
          onClick={nextSlide} 
          className="law-slider__nav-button law-slider__nav-button--next"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          &#8250;
        </motion.button>
      </motion.div>
    </motion.div>
  );
};

export default LawSlider;