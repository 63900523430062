import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity';
import { useLanguage } from '../../components/languageUtils';
import { Link } from 'react-router-dom';

const NewsPage = () => {
  const [posts, setPosts] = useState({
    newsPost: [],
    eventsPost: [],
    noticesPost: []
  });
  const [loading, setLoading] = useState({
    newsPost: true,
    eventsPost: true,
    noticesPost: true
  });
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const { language, translations } = useLanguage();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchPosts = async () => {
      console.log('Fetching posts for language:', language);
      try {
        const query = `{
          "newsPost": *[_type == "newsPost" && language == $language] {
            title, 
            "slug": slug.current,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body, 
            publishDate
          },
          "eventsPost": *[_type == "eventsPost" && language == $language] {
            title, 
            "slug": slug.current,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body, 
            publishDate,
            eventDate
          },
          "noticesPost": *[_type == "noticesPost" && language == $language] {
            title, 
            "slug": slug.current,
            mainImage{
              asset->{
                _id,
                url
              },
              alt
            },
            body, 
            publishDate
          }
        }`;

        console.log('Executing Sanity query:', query);
        console.log('Language parameter:', language);

        const result = await sanityClient.fetch(query, { language });
        console.log('Fetched posts result:', result);

        // Sort posts based on active date
        const sortedResult = {
          newsPost: sortPostsByDate(result.newsPost, 'publishDate'),
          eventsPost: sortPostsByDate(result.eventsPost, 'eventDate'),
          noticesPost: sortPostsByDate(result.noticesPost, 'publishDate')
        };

        setPosts(sortedResult);
        setLoading({
          newsPost: false,
          eventsPost: false,
          noticesPost: false
        });
      } catch (err) {
        console.error('Error fetching posts:', err);
        setError("Failed to load posts: " + err.message);
        setLoading({
          newsPost: false,
          eventsPost: false,
          noticesPost: false
        });
      }
    };

    fetchPosts();
  }, [language]);

  // Helper function to sort posts by date
  const sortPostsByDate = (posts, dateField) => {
    if (!Array.isArray(posts)) return [];
    
    // Separate posts with and without dates
    const postsWithDates = posts.filter(post => post[dateField]);
    const postsWithoutDates = posts.filter(post => !post[dateField]);

    // Sort posts with dates in descending order
    const sortedPosts = postsWithDates.sort((a, b) => {
      const dateA = new Date(a[dateField]);
      const dateB = new Date(b[dateField]);
      return dateB - dateA;
    });

    // Append posts without dates at the end
    return [...sortedPosts, ...postsWithoutDates];
  };

  useEffect(() => {
    console.log('Current posts state:', posts);
    console.log('Current loading state:', loading);
  }, [posts, loading]);

  if (error) return <div className="text-center py-10 text-red-600">{error}</div>;

  const postTypes = ['newsPost', 'eventsPost', 'noticesPost'];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 font-sans text-gray-800">
      {postTypes.map((postType) => (       
        <PostTypeSection 
          key={postType} 
          postType={postType} 
          posts={posts[postType] || []}
          isMobile={isMobile}
          translations={translations}
          language={language}
          loading={loading[postType]}
        />
      ))}
    </div>
  );
};

const PostTypeSection = ({ postType, posts, isMobile, translations, language, loading }) => {
  console.log(`Rendering ${postType} section. Loading: ${loading}, Posts: `, posts);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nextSlide = () => {
    if (isAnimating || currentIndex + 3 >= posts.length) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 3, posts.length - 3));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prevSlide = () => {
    if (isAnimating || currentIndex === 0) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 3, 0));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString(language === 'en' ? 'en-US' : 'sq-AL', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength - 1) + '…';
  };

  const getLocalizedNewsPrefix = (language) => {
    return language === 'en' ? 'news' : 'lajme';
  };

  const renderPostItem = (post) => {
    const newsPrefix = getLocalizedNewsPrefix(language);
    const permalink = `/${language}/${newsPrefix}/${post.slug}`;
    const displayDate = postType === 'eventsPost' && post.eventDate ? 
      formatDate(post.eventDate) : formatDate(post.publishDate);

    return (
      <div key={post.slug} className="bg-white border border-gray-200 p-4 flex flex-col h-full transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
        {post.mainImage?.asset?.url && (
          <img
            src={post.mainImage.asset.url}
            alt={post.mainImage.alt || post.title}
            className="w-full h-[150px] object-cover mb-4"
            onError={(e) => {
              console.error('Image failed to load:', post.mainImage.asset.url);
              e.target.onerror = null; 
              e.target.src = 'https://via.placeholder.com/300x150?text=Image+Not+Available';
            }}
          />
        )}
        <div className="flex flex-col flex-grow">
          {displayDate && (
            <p className="text-sm text-gray-500 mb-2">
              {displayDate}
            </p>
          )}
          <h3 className="text-lg font-semibold text-red-600 mb-2">
            {truncateText(post.title, 60)}
          </h3>
          <div className="flex-grow overflow-hidden mb-4">
            <p className="text-sm text-gray-600">
              {truncateText(post.body?.[0]?.children?.[0]?.text || '', 100)}
            </p>
          </div>
          <div className="mt-auto">
            <Link to={permalink} className="inline-block text-sm font-bold text-red-600 hover:text-red-700 transition duration-300">
              {language === 'en' ? (translations?.common?.readMore || 'Read More') : 'Lexo më shumë'}
            </Link>
          </div>
        </div>
      </div>
    );
  };

  const getSectionTitle = () => {
    switch(postType) {
      case 'newsPost':
        return language === 'en' ? (translations?.menu?.news || 'News') : 'Lajme';
      case 'eventsPost':
        return language === 'en' ? (translations?.menu?.events || 'Events') : 'Ngjarje';
      case 'noticesPost':
        return language === 'en' ? (translations?.menu?.notices || 'Notices') : 'Njoftime';
      default:
        return '';
    }
  };

  return (
    <div className="mb-10 relative">
      <h2 className="bg-red-600 text-white py-2 px-4 text-lg font-normal mb-4">
        {getSectionTitle()}
      </h2>
      {loading ? (
        <div className="text-center py-10">{translations?.common?.loading || 'Loading...'}</div>
      ) : !posts || posts.length === 0 ? (
        <div className="text-center py-10 text-gray-500 italic bg-gray-100">
          {language === 'en' ? (translations?.common?.noContent || 'No content available') : 'Nuk ka përmbajtje të disponueshme'}
        </div>
      ) : (
        <div className="bg-gray-100 p-4">
          {isMobile ? (
            <>
              {renderPostItem(posts[currentIndex])}
              {posts.length > 1 && (
                <div className="flex justify-between mt-4">
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={prevSlide} 
                    disabled={currentIndex === 0 || isAnimating}
                  >
                    ❮
                  </button>
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={nextSlide} 
                    disabled={currentIndex + 1 >= posts.length || isAnimating}
                  >
                    ❯
                  </button>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 auto-rows-fr">
                {posts.slice(currentIndex, currentIndex + 3).map(renderPostItem)}
              </div>
              {posts.length > 3 && (
                <>
                  <button 
                    className="absolute top-1/2 -left-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={prevSlide} 
                    disabled={currentIndex === 0 || isAnimating}
                  >
                    ❮
                  </button>
                  <button 
                    className="absolute top-1/2 -right-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={nextSlide} 
                    disabled={currentIndex + 3 >= posts.length || isAnimating}
                  >
                    ❯
                  </button>
                </>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsPage;