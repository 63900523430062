import React from 'react';
import { useLanguage } from '../../components/languageUtils'; // Adjust the import path as needed
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Pin = () => {
  const pinBase64 = '../pin.png'; // Replace with your base64 string
  const leftImageBase64 = '../Asset 3.png'; // Replace with your left image base64 string
  const rightImageBase64 = '../Asset 3.png'; // Replace with your right image base64 string

  const { language } = useLanguage();
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1, // Adjust this value to control when the animation triggers
  });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const translations = {
    en: {
      title: "RELATIONSHIP WITH OTHER PUBLIC SECTOR INSTITUTIONS",
      description: "The Albanian Investment Corporation fosters strong partnerships with key public sector institutions to ensure alignment with national development goals. By collaborating with government bodies, local municipalities, and regulatory agencies, we streamline the investment process and create a conducive environment for both domestic and international investors. These relationships enable us to work efficiently within Albania's regulatory framework, ensuring that our projects meet the highest standards of transparency and sustainability. Together, we are building a solid foundation for long-term economic growth and prosperity.",
      cards: [
        {
          title: "RELATIONSHIP WITH LINE MINISTRIES",
          content: "The Albanian Investment Corporation (AIC) works closely with line ministries to support sustainable economic development and attract investments to Albania. This partnership includes close coordination to identify priority projects, improve administrative procedures, and create favorable conditions for both foreign and domestic investors. Through regular meetings and joint initiatives, AIC and the ministries ensure alignment with the government’s strategic objectives for a modern and sustainable investment climate."
        },
        {
          title: "RELATIONSHIP WITH AIDA",
          content: "The Albanian Investment Corporation (AIC) and the Albanian Investment Development Agency (AIDA) collaborate to foster strategic investments and economic development in Albania by coordinating the promotion of key projects and creating new opportunities for investors.Through information sharing, promotional activities, and streamlined procedures, this partnership improves the investment climate and establishes sustainable conditions for long-term economic growth in Albania."
        },
        {
          title: "RELATIONSHIP WITH THE ALBANIAN DEVELOPMENT FUND (ADF)",
          content: "The Albanian Investment Corporation (AIC) and the Albanian Development Fund (ADF) collaborate to promote projects that strengthen infrastructure and drive economic development in various regions of Albania. This partnership aims to improve living conditions and enhance the economic potential of local communities, promoting sustainable development.Through the exchange of expertise and resources, the two institutions implement projects in sectors such as tourism, infrastructure, and innovation, contributing to long-term development and improving the standard of living in Albania."
        }
      ]
    },
    sq: {
      title: "MARRËDHËNIA ME INSTITUCIONET E TJERA TË SEKTORIT PUBLIK",
      description: "Korporata Shqiptare e Investimeve kultivon partneritete të qëndrueshme me institucionet kryesore të sektorit publik, duke siguruar përputhshmëri të plotë me objektivat kombëtare të zhvillimit. Përmes bashkëpunimit të ngushtë me organet qeveritare, bashkitë lokale dhe agjencitë rregullatore, ne lehtësojmë procesin e investimeve dhe krijojmë një mjedis të favorshëm për investitorët vendas dhe të huaj. Këto marrëdhënie na mundësojnë të operojmë me efikasitet brenda kuadrit rregullator të Shqipërisë, duke siguruar që projektet tona të përmbushin standardet më të larta të transparencës dhe qëndrueshmërisë. Së bashku, po ndërtojmë themelet për një rritje të qëndrueshme ekonomike dhe një prosperitet afatgjatë.",
      cards: [
        {
          title: "MARRËDHËNIA ME MINISTRITË E LINJËS",
          content: "Korporata Investimeve Shqiptare bashkëpunon ngushtë me ministritë e linjës për të mbështetur zhvillimin ekonomik të qëndrueshëm dhe për të tërhequr investime në Shqipëri. Ky partneritet përfshin koordinim të ngushtë për identifikimin e projekteve prioritare, përmirësimin e procedurave administrative dhe krijimin e kushteve të favorshme për investitorë të huaj dhe vendas. Përmes takimeve të rregullta dhe nismave të përbashkëta, Korporata Investimeve Shqiptare dhe ministritë sigurojnë përputhjen me objektivat strategjike të qeverisë për një klimë investimesh moderne dhe të qëndrueshme."
        },
        {
          title: "MARRËDHËNIA ME AIDA-n",
          content: "Korporata Investimeve Shqiptare dhe Agjencia Shqiptare e Zhvillimit të Investimeve (AIDA) bashkëpunojnë për të nxitur investimet strategjike dhe zhvillimin ekonomik të Shqipërisë, duke koordinuar promovimin e projekteve kryesore dhe krijimin e mundësive të reja për investitorët.Nëpërmjet ndarjes së informacionit, aktiviteteve promocionale dhe lehtësimit të procedurave, ky partneritet përmirëson klimën e investimeve dhe krijon kushte të qëndrueshme për zhvillim ekonomik afatgjatë në Shqipëri."
        },
        {
          title: "MARRËDHËNIA ME FONDIN SHQIPTAR TË ZHVILLIMIT (FSHZH)",
          content: "Korporata Investimeve Shqiptare dhe Fondi Shqiptar i Zhvillimit (FSHZH) bashkëpunojnë për të nxitur projekte që forcojnë infrastrukturën dhe zhvillimin ekonomik në rajone të ndryshme të Shqipërisë. Ky partneritet synon përmirësimin e kushteve të jetesës dhe rritjen e potencialit ekonomik të komuniteteve lokale, duke promovuar një zhvillim të qëndrueshëm.Përmes shkëmbimit të ekspertizës dhe burimeve, dy institucionet realizojnë projekte në sektorë si turizmi, infrastruktura dhe inovacioni, duke kontribuar në zhvillimin afatgjatë dhe përmirësimin e standardit të jetesës në Shqipëri."
        }
      ]
    }
  };

  const t = translations[language];

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.6, 
        ease: "easeOut",
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.6, ease: "easeOut" }
    }
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
      className="text-center font-sans text-gray-800 p-4 sm:p-8 md:p-16 lg:p-20 bg-gray-100"
    >
      <motion.div variants={itemVariants} className="relative bg-red-600 text-white p-6 sm:p-10 md:p-16 lg:p-24 rounded-[50px] max-w-[95%] sm:max-w-[90%] mx-auto min-h-[250px] sm:min-h-[300px] md:min-h-[400px] lg:min-h-[500px] overflow-hidden shadow-2xl flex flex-col justify-start items-center text-center">
        <img src={leftImageBase64} alt="Left side view" className="absolute top-0 left-[-24%] h-full w-[30%] z-10 clip-path-left hidden md:block" />
        <div className="w-full sm:max-w-[85%] md:max-w-[80%] lg:max-w-[70%] mt-2 sm:mt-0">
          <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold mb-2 sm:mb-4">{t.title}</h2>
          <p className="text-sm sm:text-base md:text-lg lg:text-xl leading-relaxed">
            {t.description}
          </p>
        </div>
        <img src={rightImageBase64} alt="Right side view" className="absolute top-0 right-[-24%] h-full w-[30%] z-10 clip-path-right hidden md:block" />
      </motion.div>
      <motion.div variants={itemVariants} className="flex flex-wrap justify-center gap-4 sm:gap-6 md:gap-8 lg:gap-10 relative top-[-40px] sm:top-[-60px] md:top-[-80px] lg:top-[-100px]">
        {t.cards.map((card, index) => (
          <motion.div 
            key={index} 
            variants={itemVariants}
            className="bg-white p-4 rounded-3xl shadow-md w-full sm:w-[calc(50%-12px)] lg:w-[360px] h-[500px] sm:h-[550px] md:h-[600px] lg:h-[650px]  text-center relative flex flex-col justify-center items-center z-20"
          >
            <div className="absolute top-2 left-[40%] transform -translate-x-1/2">
              <img src={pinBase64} alt="Pin icon" className="w-auto h-auto" />
            </div>
            <div className="flex flex-col items-center justify-center h-full text-center">
              <h3 className="pt-16 sm:pt-20 text-lg sm:text-xl font-bold mb-2 text-red-600">{card.title}</h3>
              <p className="text-xs sm:text-sm md:text-base leading-relaxed text-gray-600 px-2">{card.content}</p>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Pin;
