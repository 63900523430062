import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Clock, Mail, MapPin, Linkedin, Instagram, Facebook, Search, ChevronDown, Network, Folder, Globe, PieChart, RefreshCw, Settings, Twitter } from 'lucide-react';
import LanguageSwitcher, { GlobalLanguageContext } from '../../components/LanguageSwitcher';

const HeaderHome = () => {
  const { t, i18n } = useTranslation(['homeHeader', 'common']);
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);
  const { globalLanguage } = useContext(GlobalLanguageContext);

  useEffect(() => {
    if (globalLanguage !== i18n.language) {
      i18n.changeLanguage(globalLanguage);
    }
  }, [globalLanguage, i18n]);

  const menuItems = [
    {
      title: t('homeHeader:menu.aboutUs'),
      submenu: [
        { title: t('homeHeader:submenus.aboutUs.visionMission'), link: `/${i18n.language}/${t('homeHeader:routes.aboutUs')}/${t('homeHeader:routes.visionMission')}` },
        { title: t('homeHeader:submenus.aboutUs.whyChooseAIC'), link: `/${i18n.language}/${t('homeHeader:routes.aboutUs')}/${t('homeHeader:routes.whyChooseAIC')}` },
        { title: t('homeHeader:submenus.aboutUs.lawsRegulations'), link: `/${i18n.language}/${t('homeHeader:routes.aboutUs')}/${t('homeHeader:routes.lawsRegulations')}` },
        { title: t('homeHeader:submenus.aboutUs.transparencyProgram'), link: `/${i18n.language}/${t('homeHeader:routes.aboutUs')}/${t('homeHeader:routes.transparencyProgram')}` },
      ],
    },
    { title: t('homeHeader:menu.news'), link: `/${i18n.language}/${t('homeHeader:routes.news')}` },
    { title: t('homeHeader:menu.projects'), link: `/${i18n.language}/${t('homeHeader:routes.projects')}` },
    {
      title: t('homeHeader:menu.openCalls'),
      submenu: [
        { title: t('homeHeader:submenus.openCalls.ongoing'), link: `/${i18n.language}/${t('homeHeader:routes.openCalls')}/${t('homeHeader:routes.ongoing')}` },
        { title: t('homeHeader:submenus.openCalls.closedCalls'), link: `/${i18n.language}/${t('homeHeader:routes.openCalls')}/${t('homeHeader:routes.closedCalls')}` },
      ],
    },
    {
      title: t('homeHeader:menu.team'),
      submenu: [
        { title: t('homeHeader:submenus.team.institutionalStructure'), link: `/${i18n.language}/${t('homeHeader:routes.team')}/${t('homeHeader:routes.institutionalStructure')}` },
        { title: t('homeHeader:submenus.team.meetTheStaff'), link: `/${i18n.language}/${t('homeHeader:routes.team')}/${t('homeHeader:routes.meetTheStaff')}` },
        { title: t('homeHeader:submenus.team.joinUs'), link: `/${i18n.language}/${t('homeHeader:routes.team')}/${t('homeHeader:routes.joinUs')}` },
      ],
    },
    {
      title: t('homeHeader:menu.networking'),
      unclickable: true,
      submenu: [
        { title: t('homeHeader:submenus.networking.lineMinistersRelationship'), unclickable: true },
        { title: t('homeHeader:submenus.networking.aidaRelationship'), unclickable: true },
        { title: t('homeHeader:submenus.networking.adfRelationship'), unclickable: true },
      ],
    },
    { title: t('homeHeader:menu.contact'), link: `/${i18n.language}/${t('homeHeader:routes.contact')}` },
  ];

  const features = [
    { Icon: Network, text: t('homeHeader:features.expertise') },
    { Icon: Folder, text: t('homeHeader:features.portfolio') },
    { Icon: Globe, text: t('homeHeader:features.solutions') },
    { Icon: PieChart, text: t('homeHeader:features.transparency') },
    { Icon: RefreshCw, text: t('homeHeader:features.partnerships') },
    { Icon: Settings, text: t('homeHeader:features.development') },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    document.body.style.overflow = isDropdownOpen ? 'auto' : 'hidden';
  };

  const toggleMobileDropdown = (index, e) => {
    e.stopPropagation();
    setOpenMobileDropdown(openMobileDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 1024;
      setIsMobile(isMobileView);

      if (!isMobileView) {
        setIsDropdownOpen(false);
        document.body.style.overflow = 'auto';
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="relative w-full text-white font-rubik min-h-[600px] h-[calc(100vh-60px)] md:min-h-[600px] md:h-screen">
      <div className="absolute inset-0">
        {/* Background Image */}
        <div className="absolute inset-0 z-0">
          <div className="absolute inset-0 bg-black/50"></div>
          <img src="/Image2.jpg" alt="Image 2" className="w-full h-full object-cover" />
        </div>

        {/* Content */}
        <div className="relative z-10 flex flex-col h-full">
          {/* Header Section with increased z-index */}
          <div className="flex-none relative z-50">
            {/* Top Info Bar */}
            <div className="hidden lg:flex justify-end items-center py-2 px-5 text-sm">
              <div className="flex gap-5">
                <span className="flex items-center"><Clock className="w-4 h-4 mr-1" /> {t('homeHeader:topInfo.workHours')}</span>
                <span className="flex items-center"><Mail className="w-4 h-4 mr-1" /> {t('homeHeader:topInfo.email')}</span>
                <span className="flex items-center"><MapPin className="w-4 h-4 mr-1" /> {t('homeHeader:topInfo.address')}</span>
              </div>
              <Link 
                to={`/${i18n.language === 'sq' ? 'sq/kontakt' : 'en/contact'}`}
                className="bg-red-600 text-white px-4 py-1 rounded-lg ml-5"
              >
                {t('homeHeader:common.investNow')}
              </Link>
            </div>

            {/* Main Menu */}
            <div className="flex justify-between items-center px-5 relative">
              <div className="py-2">
                <Link to="/">
                  <img src="../AIClogo.png" alt="Albanian Investment Corporation Logo" className="h-24 w-72 object-contain" />
                </Link>
              </div>

              {isMobile && (
                <button 
                  className={`lg:hidden flex flex-col justify-center items-center w-8 h-6 space-y-1.5 ${isDropdownOpen ? 'open' : ''}`} 
                  onClick={toggleDropdown}
                >
                  <span className="w-full h-0.5 bg-white transform transition duration-300 ease-in-out"></span>
                  <span className="w-full h-0.5 bg-white transform transition duration-300 ease-in-out"></span>
                  <span className="w-full h-0.5 bg-white transform transition duration-300 ease-in-out"></span>
                </button>
              )}

              <nav className={`hidden lg:flex ${isDropdownOpen ? 'active' : ''}`}>
                <ul className="flex">
                  {menuItems.map((item, index) => (
                    <li key={index} className="relative group">
                      {item.link && !item.unclickable ? (
                        <Link to={item.link} className="px-4 py-2 block hover:bg-white/10 transition duration-300">
                          {item.title}
                        </Link>
                      ) : (
                        <span className="px-4 py-2 block cursor-default hover:bg-white/10 transition duration-300">
                          {item.title}
                        </span>
                      )}
                      {item.submenu && (
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 bg-red-600 rounded-md hidden group-hover:block w-56 shadow-lg z-50">
                          <ul className="py-2">
                            {item.submenu.map((subItem, subIndex) => (
                              <li key={subIndex} className="px-2">
                                {subItem.link && !subItem.unclickable ? (
                                  <Link 
                                    to={subItem.link} 
                                    className="block px-2 py-2 text-left hover:bg-white hover:text-red-600 transition duration-300 rounded"
                                  >
                                    {subItem.title}
                                  </Link>
                                ) : (
                                  <span className="block px-2 py-2 text-left cursor-default hover:bg-white hover:text-red-600 transition duration-300 rounded">
                                    {subItem.title}
                                  </span>
                                )}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>

              {/* Language Switcher and Social Media Icons */}
              <div className={`hidden lg:flex items-center gap-4 mr-24`}>
                <LanguageSwitcher />
                <div className="flex gap-2">
                  <a href="https://www.linkedin.com/company/the-albanian-investment-corporation/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><Linkedin className="w-4 h-4" /></a>
                  <a href="https://www.instagram.com/albanianinvestmentcorporation/" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><Instagram className="w-4 h-4" /></a>
                  <a href="https://www.facebook.com/AlbanianInvestmentCorporation" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><Facebook className="w-4 h-4" /></a>
                  <a href="https://x.com/albania_aic/" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><Twitter className="w-4 h-4" /></a>
                </div>
              </div>
            </div>
            <div className="border-b border-white mx-5"></div>
          </div>

          {/* Slider Content */}
          <div className="flex-grow flex items-center -mt-60 relative z-20">
            <div className="w-[80%] md:w-[70%] mx-auto text-left">
              <p className="text-white font-semibold mb-2 text-sm sm:text-base lg:text-xl relative">
                {t('homeHeader:pages.home.subtitle')}
                <span className="absolute bottom-[-15px] left-[0%] w-20 h-2.5 bg-red-600"></span>
              </p>
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mt-8">
                {t('homeHeader:pages.home.title')}
              </h1>
            </div>
          </div>

          {/* Red Boxes */}
          <div className="flex-none">
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[100%] lg:w-[80%] md:w-[90%] bg-red-600 text-white flex flex-wrap justify-between items-stretch rounded-t-3xl z-30 overflow-hidden">
              {features.map(({ Icon, text }, index) => (
                <div key={index} className="flex flex-col items-center justify-center text-center px-2 w-1/2 sm:w-1/3 lg:w-1/6 py-4 md:py-6 lg:py-8 relative">
                  <Icon className="w-5 h-5 sm:w-10 sm:h-10 lg:w-12 lg:h-12 mb-2 sm:mb-3" />
                  <span className="text-xs sm:text-sm uppercase max-w-[100px] sm:max-w-[120px] lg:max-w-[140px] leading-tight">
                    {text}
                  </span>
                  {(index !== features.length - 1) && (
                    <>
                      <div className="absolute right-0 top-[15%] h-[70%] w-0.5 bg-white/30 hidden sm:block"></div>
                      <div className="absolute bottom-0 left-[15%] w-[70%] h-0.5 bg-white/30 sm:hidden"></div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMobile && (
        <div className={`fixed inset-0 bg-white z-[100] transform ${isDropdownOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <button className="absolute top-5 right-5 text-red-600 text-3xl" onClick={toggleDropdown}>X</button>
          <div className="pt-20 px-5">
            <nav>
              <ul>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <div className="flex justify-between items-center">
                      {item.link && !item.unclickable ? (
                        <Link to={item.link} className="text-red-600 font-bold py-2 block hover:bg-red-100 transition duration-300" onClick={toggleDropdown}>
                          {item.title}
                        </Link>
                      ) : (
                        <span className="text-red-600 font-bold py-2 block cursor-default hover:bg-red-100 transition duration-300">
                          {item.title}
                        </span>
                      )}
                      {item.submenu && (
                        <button
                          className="p-2"
                          onClick={(e) => toggleMobileDropdown(index, e)}
                        >
                          <ChevronDown
                            className={`w-6 h-6 text-red-600 transform transition-transform duration-300 ${openMobileDropdown === index ? 'rotate-180' : ''}`}
                          />
                        </button>
                      )}
                    </div>
                    {item.submenu && (
                      <div className={`pl-5 ${openMobileDropdown === index ? 'block' : 'hidden'}`}>
                        <ul>
                          {item.submenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              {subItem.link && !subItem.unclickable ? (
                                <Link 
                                  to={subItem.link} 
                                  onClick={toggleDropdown} 
                                  className="text-red-600 font-bold py-2 block hover:bg-red-100 transition duration-300"
                                >
                                  {subItem.title}
                                </Link>
                              ) : (
                                <span className="text-red-600 font-bold py-2 block cursor-default hover:bg-red-100 transition duration-300">
                                  {subItem.title}
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className="border-t border-red-600 my-5"></div>
            <div className="relative">
              <input type="text" placeholder={t('homeHeader:common.search')} className="w-full p-2 border-2 border-red-600 rounded-md" />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-600 w-5 h-5" />
            </div>
            <div className="flex gap-5 mt-5">
              <LanguageSwitcher />
            </div>
            <Link 
              to={`/${i18n.language === 'sq' ? 'sq/kontakt' : 'en/contact'}`}
              className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300 block text-center mt-5"
              onClick={toggleDropdown}
            >
              {t('homeHeader:common.investNow')}
            </Link>
            <div className="flex gap-5 mt-5">
              <a href="https://www.facebook.com/AlbanianInvestmentCorporation" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><Facebook className="w-6 h-6 text-red-600" /></a>
              <a href="https://www.instagram.com/albanianinvestmentcorporation/" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><Instagram className="w-6 h-6 text-red-600" /></a>
              <a href="https://www.linkedin.com/company/the-albanian-investment-corporation/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><Linkedin className="w-6 h-6 text-red-600" /></a>
              <a href="https://x.com/albania_aic/" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><Twitter className="w-6 h-6 text-red-600" /></a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderHome;