import React, { useState, useEffect, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { GlobalLanguageContext } from '../../components/LanguageSwitcher';
import './Logo.css';

const FullWidthLogoCarousel = () => {
  const { globalLanguage } = useContext(GlobalLanguageContext);

  const translations = {
    en: {
      subtitle: "SYNERGIZE AND SUCCEED",
      title: "COLLABORATIVE PARTNERSHIPS WITH AIC"
    },
    sq: {
      subtitle: "SINERGJIZONI DHE KINI SUKSES",
      title: "PARTNERITETE BASHKËPUNUESE ME AIC"
    }
  };

  const allLogos = [
    { src: '../verona.png', link: 'https://www.univr.it/it/' },
    { src: '../veronafiere.png', link: 'https://www.veronafiere.it/' },
    { src: '../messe.png', link: 'https://www.messe-berlin.de/en' },
    { src: '../risi.png', link: 'https://www.risialbania.al/' },
    { src: '../add.png', link: 'https://www.agenziademanio.it/' },
    { src: '../ita.png', link: 'https://www.ice.it/en/' },
    { src: '../ifema.png', link: 'https://www.ifema.es/' },
    { src: '../dyrrah.png', link: 'https://dcfa.al/en/' },
  ];

  const [visibleLogos, setVisibleLogos] = useState(5);
  const totalLogos = allLogos.length;
  const [currentIndex, setCurrentIndex] = useState(visibleLogos);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const carouselWrapperRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 480) {
        setVisibleLogos(1);
      } else if (window.innerWidth <= 768) {
        setVisibleLogos(2);
      } else if (window.innerWidth <= 992) {
        setVisibleLogos(3);
      } else if (window.innerWidth <= 1200) {
        setVisibleLogos(4);
      } else {
        setVisibleLogos(5);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleTransitionEnd = () => {
      if (currentIndex >= totalLogos + visibleLogos) {
        setIsTransitioning(false);
        setCurrentIndex(visibleLogos);
      }
    };

    const carouselElement = carouselWrapperRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('transitionend', handleTransitionEnd);
    }

    return () => {
      if (carouselElement) {
        carouselElement.removeEventListener('transitionend', handleTransitionEnd);
      }
    };
  }, [currentIndex, totalLogos, visibleLogos]);

  useEffect(() => {
    if (!isTransitioning) {
      setTimeout(() => {
        setIsTransitioning(true);
      }, 50);
    }
  }, [isTransitioning]);

  const carouselStyle = {
    transform: `translateX(-${currentIndex * (100 / visibleLogos)}%)`,
    transition: isTransitioning ? 'transform 0.3s ease-in-out' : 'none',
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      className="carousel-container"
      initial="hidden"
      animate="visible"
      variants={containerVariants}
    >
      <div className="content-wrapper">
        <motion.h2 className="subtitle" variants={itemVariants}>
          {translations[globalLanguage].subtitle}
        </motion.h2>
        <motion.div className="title-container" variants={itemVariants}>
          <div className="red-line"></div>
          <h2 className="title">{translations[globalLanguage].title}</h2>
        </motion.div>
        <motion.div className="carousel" variants={itemVariants}>
          <div
            ref={carouselWrapperRef}
            className="carousel-wrapper"
            style={carouselStyle}
          >
            {allLogos.slice(-visibleLogos).map((logo, index) => (
              <div key={`start-${index}`} className="carousel-item">
                <a href={logo.link} target="_blank" rel="noopener noreferrer">
                  <img src={logo.src} alt={`Logo ${index + 1}`} className="logo" />
                </a>
              </div>
            ))}

            {allLogos.map((logo, index) => (
              <div key={index} className="carousel-item">
                <a href={logo.link} target="_blank" rel="noopener noreferrer">
                  <img src={logo.src} alt={`Logo ${index + 1}`} className="logo" />
                </a>
              </div>
            ))}

            {allLogos.slice(0, visibleLogos).map((logo, index) => (
              <div key={`end-${index}`} className="carousel-item">
                <a href={logo.link} target="_blank" rel="noopener noreferrer">
                  <img src={logo.src} alt={`Logo ${index + 1}`} className="logo" />
                </a>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FullWidthLogoCarousel;
