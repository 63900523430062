
import React from 'react';
import TeamMembersShowcase from '../Team/MeetTheStaff'; // Import the TeamMembersShowcase component
import './HomeTeam.css';
const HomeTeam = () => {
  return (
    <div>
      <TeamMembersShowcase />  {/* Use the TeamMembersShowcase component */}
    </div>
  );
};

export default HomeTeam;