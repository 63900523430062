import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ChevronRight, Play, UserCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './Section2.css'

const InvestmentSection = () => {
  const { t, i18n } = useTranslation('homePage');
  const [showVideo, setShowVideo] = useState(false);
  const location = useLocation();

  const [refHeader, inViewHeader] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refParagraphs, inViewParagraphs] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refButton, inViewButton] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refImage1, inViewImage1] = useInView({ triggerOnce: true, threshold: 0.1 });
  const [refImage2, inViewImage2] = useInView({ triggerOnce: true, threshold: 0.1 });

  const openVideo = () => setShowVideo(true);
  const closeVideo = () => setShowVideo(false);

  const fadeInUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6, ease: "easeOut" } },
  };

  const getProjectsRoute = () => {
    return i18n.language === 'sq' ? '/sq/projekte' : '/en/projects';
  };

  return (
    <div className="w-full bg-gray-100 py-16 md:py-24 pb-50 md:pb-[25rem]">
      <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col xl:flex-row items-start justify-between">
        <div className="flex flex-col xl:flex-row items-start">
          <div className="w-full xl:w-[60%] mb-12 xl:mb-0 pr-0 xl:pr-16">
            <motion.div
              ref={refHeader}
              initial="hidden"
              animate={inViewHeader ? "visible" : "hidden"}
              variants={fadeInUpVariants}
            >
              <div className="w-16 h-1 bg-red-600 mb-4"></div>
              <h2 className="text-gray-800 text-xl mb-2 font-semibold">{t('investmentSection.subtitle')}</h2>
              <h1 className="text-red-600 text-3xl md:text-4xl xl:text-5xl font-bold mb-8">
                {t('investmentSection.title')}
              </h1>
            </motion.div>
            
            <motion.div
              ref={refParagraphs}
              initial="hidden"
              animate={inViewParagraphs ? "visible" : "hidden"}
              variants={fadeInUpVariants}
            >
              <p className="text-gray-700 text-xl mb-8 leading-relaxed">
                {t('investmentSection.paragraph1')}
              </p>
              <p className="text-gray-600 text-xl mb-10 leading-relaxed">
                {t('investmentSection.paragraph2')}
              </p>
              <p className="text-gray-600 text-xl mb-10 leading-relaxed">
                {t('investmentSection.paragraph3')}
              </p>
            </motion.div>
            
            <motion.div
              ref={refButton}
              initial="hidden"
              animate={inViewButton ? "visible" : "hidden"}
              variants={fadeInUpVariants}
            >
              <Link to={getProjectsRoute()} className="inline-block">
                <button className="bg-red-600 hover:bg-red-700 transition-colors duration-300 text-white py-4 px-10 rounded-full text-xl font-bold flex items-center">
                  {t('investmentSection.viewProjects')}
                  <ChevronRight className="ml-3" size={24} />
                </button>
              </Link>
            </motion.div>
          </div>
          
          <div className="w-full xl:w-[35%] relative mt-12 xl:mt-0">
            <div className="relative md:flex md:flex-row xl:block">
              <motion.div
                ref={refImage1}
                initial="hidden"
                animate={inViewImage1 ? "visible" : "hidden"}
                variants={fadeInUpVariants}
                className="relative md:flex md:flex-row xl:block section-photo-1"
              >
                <div className="relative w-full md:w-[65%] pb-[110%] rounded-3xl overflow-hidden shadow-2xl xl:mr-auto mb-24 md:mb-0">
                  <img 
                    src="/tirana.jpg" 
                    alt={t('investmentSection.tiranaAlt')} 
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                  <motion.button 
                    className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-70 hover:bg-opacity-90 transition-all duration-300 rounded-full w-16 h-16 flex justify-center items-center cursor-pointer text-red-600" 
                    onClick={openVideo}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Play size={28} />
                  </motion.button>
                  <div className="absolute top-4 right-4 bg-red-600 text-white py-2 px-4 rounded-xl shadow-lg">
                    <span className="block text-xs font-medium">{t('investmentSection.since')}</span>
                    <span className="text-2xl font-extrabold">2019</span>
                  </div>
                </div>
              </motion.div>
              
              <motion.div 
                ref={refImage2}
                initial="hidden"
                animate={inViewImage2 ? "visible" : "hidden"}
                variants={fadeInUpVariants}
                className="
                  relative w-[100%] h-[40rem] mx-auto
                  sm:w-[100%] sm:h-[40rem] sm:relative
                  md:absolute md:bottom-[-230px] md:right-0 md:w-[50%] md:h-[36rem]
                  lg:bottom-[-290px] lg:right-0 lg:w-[40%] lg:h-[36rem]
                  xl:-bottom-70 xl:-right-20 xl:w-[70%] xl:h-[32rem]
                  bg-gray-200 rounded-3xl overflow-hidden shadow-xl mt-8 md:mt-0
                "
              >
                <img 
                  src="/Elira Kokona Portrait.jpg" 
                  alt={t('investmentSection.executiveDirectorAlt')} 
                  className="
                    absolute top-0 left-0 
                    w-[260%] h-full object-cover object-[10%_30%]
                    md:w-[260%] md:object-[8%_30%]
                    lg:w-[300%] lg:object-[2%_50%]
                    xl:w-[250%] xl:object-[6%_40%]
                  "
                />
                <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-90 p-4 text-center">
                  <Link to="/team/meet-the-staff/elira-kokona" className="block">
                    <h3 className="text-2xl md:text-3xl text-red-600 font-['Corinthiago',cursive] mb-3 font-bold hover:underline">
                      {t('investmentSection.executiveDirectorName')}
                    </h3>
                  </Link>
                  <p className="text-gray-600 text-sm">{t('investmentSection.executiveDirectorTitle')}</p>
                </div>
                <div className="absolute bottom-4 right-4 bg-red-600 rounded-full p-1">
                  <UserCircle size={24} className="text-white" />
                </div>
              </motion.div>
            </div>
          </div>
        </div>
        </div>
      </div>

      {showVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50" onClick={closeVideo}>
          <div className="relative w-full max-w-2xl lg:max-w-lg mx-4">
            <div className="aspect-w-16 aspect-h-9 lg:aspect-h-6">
              <iframe 
                src="/video.mp4" 
                frameBorder="0"
                allowFullScreen
                className="w-full h-full"
              ></iframe>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvestmentSection;
