import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import sanityClient from '../../sanity';
import BlockContent from '@sanity/block-content-to-react';
import { ArrowLeft, ArrowRight, ExternalLink, Calendar, Globe, Tag } from 'lucide-react';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ProjectsDetails = () => {
  const { slug } = useParams();
  const [project, setProject] = useState(null);
  const [nextProject, setNextProject] = useState(null);
  const [prevProject, setPrevProject] = useState(null);
  const [news, setNews] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "projectsPost" && slug.current == $slug]{
          title,
          language,
          slug,
          projectDate,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          gallery[]{asset->{_id, url}},
          pdfFiles[]{
            asset->{url},
            customName
          },
          body[]{
            ...,
            _type == "image" => {
              ...,
              asset->{
                _id,
                url
              }
            }
          },
          tags[]->{
            title
          },
          category,
          metaTitle,
          metaDescription,
          "next": *[_type == "projectsPost" && language == ^.language && dateTime(projectDate) > dateTime(^.projectDate)] | order(projectDate asc) [0]{ title, slug },
          "previous": *[_type == "projectsPost" && language == ^.language && dateTime(projectDate) < dateTime(^.projectDate)] | order(projectDate desc) [0]{ title, slug }
        }`,
        { slug }
      )
      .then((data) => {
        console.log("Fetched project data:", data[0]);
        setProject(data[0]);
        setNextProject(data[0]?.next);
        setPrevProject(data[0]?.previous);
        window.scrollTo(0, 0);

        // Fetch news based on the project's language
        sanityClient
          .fetch(
            `*[_type == "newsPost" && language == $language]{
              title,
              slug,
              mainImage{
                asset->{
                  _id,
                  url
                },
                alt
              },
              publishDate,
              body
            }`,
            { language: data[0].language }
          )
          .then((newsData) => setNews(newsData))
          .catch(console.error);
      })
      .catch(console.error);
  }, [slug]);

  const handleNavigation = (projectSlug) => {
    navigate(`/projects/${projectSlug}`);
    window.scrollTo(0, 0);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === project.gallery.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? project.gallery.length - 1 : prevIndex - 1
    );
  };

  const nextNews = () => {
    setCurrentNewsIndex((prevIndex) =>
      prevIndex === news.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevNews = () => {
    setCurrentNewsIndex((prevIndex) =>
      prevIndex === 0 ? news.length - 1 : prevIndex - 1
    );
  };

  const limitTitleLength = (title, limit = 20) => {
    return title.length > limit ? title.substring(0, limit) + '...' : title;
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  };

  const getLanguageDisplay = (langCode) => {
    return langCode === 'sq' ? 'Shqip' : 'English';
  };

  const truncateText = (text, maxLength) => {
    if (!text) return '';
    return text.length <= maxLength ? text : text.substr(0, maxLength) + '...';
  };

  const serializers = {
    types: {
      block: (props) => {
        switch (props.node.style) {
          default:
            return BlockContent.defaultSerializers.types.block(props);
        }
      },
      image: ({node}) => {
        if (!node || !node.asset) {
          console.log("Invalid image node:", node);
          return null;
        }
        
        const imageUrl = node.asset.url || urlFor(node).width(1500).url();
        
        return (
          <figure className="my-8">
            <img
              src={imageUrl}
              alt={node.alt || ' '}
              className="w-full rounded-lg shadow-lg"
              loading="lazy"
            />
            {node.caption && (
              <figcaption className="text-center text-sm text-gray-600 mt-2">
                {node.caption}
              </figcaption>
            )}
          </figure>
        );
      },
    },
    marks: {
      link: ({mark, children}) => {
        const { blank, href } = mark;
        return blank ? 
          <a href={href} target="_blank" rel="noopener noreferrer">{children}</a> :
          <a href={href}>{children}</a>;
      },
    },
  };

  if (!project) return <div className="text-center py-10">Loading...</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 flex flex-col lg:flex-row lg:justify-between">
      <div className="flex-1 max-w-3xl bg-white rounded-lg shadow-md p-8">
        <h1 className="text-4xl font-bold text-gray-800 mb-5 pb-2 border-b-2 border-red-600">
          {project.title}
        </h1>
        <div className="flex items-center text-sm text-gray-600 mb-5">
          <Calendar size={16} className="mr-2" />
          <span className="mr-4">{formatDate(project.projectDate)}</span>
          <Globe size={16} className="mr-2" />
          <span>{getLanguageDisplay(project.language)}</span>
        </div>

        {project.mainImage?.asset?.url && (
          <img
            src={project.mainImage.asset.url}
            alt={project.mainImage.alt || project.title}
            className="w-full rounded-lg mb-8"
          />
        )}

        <div className="prose max-w-none mb-8">
          <BlockContent
            blocks={project.body}
            serializers={serializers}
            projectId={sanityClient.config().projectId}
            dataset={sanityClient.config().dataset}
            imageOptions={{w: 800, fit: 'max'}}
          />
        </div>

        {project.pdfFiles && project.pdfFiles.length > 0 && (
          <div className="bg-gray-100 border border-gray-300 rounded-lg p-6 mt-8">
            <h3 className="text-xl font-semibold text-gray-800 mb-4">Related Documents</h3>
            <div className="space-y-4">
              {project.pdfFiles.map((pdfFile, index) => (
                <div key={index} className="flex flex-col sm:flex-row gap-4 items-center">
                  <div className="bg-red-600 text-white py-2 px-4 rounded-md font-semibold">
                    <span>{pdfFile.customName || `PDF ${index + 1}`}</span>
                  </div>
                  <a 
                    href={pdfFile.asset.url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center bg-gray-200 hover:bg-gray-300 text-gray-800 py-2 px-4 rounded-md transition duration-300"
                  >
                    <ExternalLink size={20} className="mr-2" />
                    <span>View PDF</span>
                  </a>
                </div>
              ))}
            </div>
          </div>
        )}

        {project.tags && project.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mt-8">
            <Tag size={20} className="text-red-600 mr-2" />
            {project.tags.map((tag, index) => (
              <span 
                key={index}
                className="bg-gray-200 text-red-600 px-3 py-1 rounded-full text-sm transition duration-300 hover:bg-red-600 hover:text-white cursor-default"
              >
                {tag.title}
              </span>
            ))}
          </div>
        )}

        {project.gallery && project.gallery.length > 0 && (
          <div className="mt-10 mb-10">
            <h3 className="text-2xl text-gray-800 mb-5">Gallery</h3>
            <div className="relative h-96 bg-gray-100 rounded-lg overflow-hidden">
              <button 
                onClick={prevImage} 
                className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-red-600 bg-opacity-70 hover:bg-opacity-90 text-white rounded-full p-2 transition duration-300 z-10"
              >
                <ArrowLeft size={20} />
              </button>
              <div className="w-full h-full flex justify-center items-center">
                <img
                  src={project.gallery[currentImageIndex].asset.url}
                  alt={`Gallery item ${currentImageIndex + 1}`}
                  className="max-w-full max-h-full object-contain"
                />
              </div>
              <button 
                onClick={nextImage} 
                className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-red-600 bg-opacity-70 hover:bg-opacity-90 text-white rounded-full p-2 transition duration-300 z-10"
              >
                <ArrowRight size={20} />
              </button>
            </div>
            <p className="text-center mt-2 text-gray-600 italic">
              {`Image ${currentImageIndex + 1} of ${project.gallery.length}`}
            </p>
          </div>
        )}

        <div className="flex flex-col sm:flex-row justify-between gap-4 mt-10">
          {prevProject && (
            <button 
              onClick={() => handleNavigation(prevProject.slug.current)} 
              className="flex items-center justify-center sm:justify-start bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 transform hover:-translate-y-1"
            >
              <ArrowLeft size={20} className="mr-2" />
              <span>Previous: {limitTitleLength(prevProject.title)}</span>
            </button>
          )}
          {nextProject && (
            <button 
              onClick={() => handleNavigation(nextProject.slug.current)} 
              className="flex items-center justify-center sm:justify-end bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-full transition duration-300 transform hover:-translate-y-1"
            >
              <span>Next: {limitTitleLength(nextProject.title)}</span>
              <ArrowRight size={20} className="ml-2" />
            </button>
          )}
        </div>
      </div>

      <div className="w-full lg:w-80 mt-10 lg:mt-0 lg:ml-8">
        <div className="sticky top-5 bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl text-gray-800 mb-5 pb-2 border-b-2 border-red-600">Latest News</h2>
          {news.length > 0 ? (
            <div>
              {news[currentNewsIndex].mainImage?.asset?.url && (
                <img
                  src={news[currentNewsIndex].mainImage.asset.url}
                  alt={news[currentNewsIndex].mainImage.alt || news[currentNewsIndex].title}
                  className="w-full h-48 object-cover rounded-lg mb-4"
                />
              )}
              <div>
                <h3 className="text-xl text-gray-800 mb-2">
                  {truncateText(news[currentNewsIndex].title, 30)}
                </h3>
                {news[currentNewsIndex].publishDate && (
                  <p className="text-sm text-gray-600 mb-2">
                    Date: {formatDate(news[currentNewsIndex].publishDate)}
                  </p>
                )}
                <div className="text-sm text-gray-600 mb-4">
                  {truncateText(news[currentNewsIndex].body[0].children[0].text, 60)}
                </div>
                <a 
                  href={`/news/${news[currentNewsIndex].slug.current}`} 
                  className="inline-block bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded text-sm font-semibold transition duration-300"
                >
                  Read More
                </a>
              </div>
              <div className="flex justify-between mt-4">
                <button onClick={prevNews} className="text-red-600 hover:text-red-700 transition duration-300">
                  <ArrowLeft size={20} />
                </button>
                <button onClick={nextNews} className="text-red-600 hover:text-red-700 transition duration-300">
                  <ArrowRight size={20} />
                </button>
              </div>
            </div>
          ) : (
            <p className="text-gray-600">No news available in {getLanguageDisplay(project.language)}.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectsDetails;
