import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../../components/languageUtils';
import { motion } from 'framer-motion';

const MissionSection = ({ loading }) => {
  const location = useLocation();
  const { language, translations } = useLanguage();

  const content = {
    en: {
      subtitle: "OUR MISSION",
      title: "INNOVATIVE INVESTING ALBANIAN INVESTMENT CORPORATION",
      description: [
        "The Albanian Investment Corporation (AIC) is dedicated to driving economic and social development through strategic national and regional investment projects. These efforts align with state policies and aim to effectively utilize state assets, mobilize public and private capital, and convert innovative ideas into viable, impactful projects.",
        "AIC plays a crucial role in assisting the government with project review and structuring, ensuring that investments are well-planned and executed to maximize benefits."
      ],
    },
    sq: {
      subtitle: "MISIONI",
      title: "KORPORATA SHQIPTARE E INVESTIMEVE (AIC)",
      description: [
        "Korporata Shqiptare e Investimeve (AIC) është e përkushtuar për të nxitur zhvillimin ekonomik dhe social nëpërmjet projekteve strategjike të investimeve kombëtare dhe rajonale. Këto përpjekje përputhen me politikat shtetërore dhe synojnë të përdorin në mënyrë efektive asetet shtetërore, të mobilizojnë kapitalin publik dhe privat dhe të shndërrojnë idetë novatore në projekte të zbatueshme dhe me ndikim.",
        "AIC luan një rol vendimtar në asistimin e qeverisë në rishikimin dhe strukturimin e projekteve, duke siguruar që investimet të jenë të planifikuara dhe të ekzekutuara mirë për të maksimizuar përfitimet."
      ],
    }
  };

  const sidebarLinks = [
    { 
      name: translations[language].submenus.aboutUs.visionMission, 
      path: `/${language}/${translations[language].routes['about-us']}/${translations[language].routes['vision-and-mission']}`
    },
    { 
      name: translations[language].submenus.aboutUs.whyChooseAIC, 
      path: `/${language}/${translations[language].routes['about-us']}/${translations[language].routes['why-choose-aic']}`
    },
    { 
      name: translations[language].submenus.aboutUs.lawsRegulations, 
      path: `/${language}/${translations[language].routes['about-us']}/${translations[language].routes['law-and-regulations']}`
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { 
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      className="flex justify-center items-center flex-col max-w-7xl mx-auto px-5 py-10"
      initial="hidden"
      animate={loading ? "hidden" : "visible"}
      variants={containerVariants}
    >
      <div className="flex justify-between items-start w-full text-gray-800">
        <motion.div className="flex-1 pr-0 lg:pr-15" variants={itemVariants}>
          <motion.div className="flex items-center mb-5" variants={itemVariants}>
            <div className="w-10 h-1.5 bg-red-600 mr-2.5"></div>
            <h3 className="text-xl font-bold text-gray-800 uppercase">{content[language].subtitle}</h3>
          </motion.div>
          <motion.h2 className="text-4xl font-bold text-red-600 mt-2.5 mb-7.5" variants={itemVariants}>{content[language].title}</motion.h2>
          {content[language].description.map((paragraph, index) => (
            <motion.p key={index} className="text-lg text-gray-600 leading-relaxed mb-5" variants={itemVariants}>{paragraph}</motion.p>
          ))}
        </motion.div>
        <motion.aside className="w-full lg:w-72" variants={itemVariants}>
          <nav className="py-5">
            <motion.h3 className="text-2xl font-bold text-gray-900 mb-6 uppercase" variants={itemVariants}>{translations[language].common.quickLinks}</motion.h3>
            <motion.ul className="space-y-4" variants={itemVariants}>
              {sidebarLinks.map((link) => (
                <motion.li key={link.path} variants={itemVariants}>
                  <Link 
                    to={link.path}
                    className={`block py-5 px-6 rounded-lg transition-all duration-300 ease-in-out text-lg font-medium
                      ${location.pathname === link.path 
                        ? 'bg-red-600 text-white shadow-md shadow-red-200' 
                        : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:-translate-y-0.5'}`}
                  >
                    {link.name}
                  </Link>
                </motion.li>
              ))}
            </motion.ul>
          </nav>
        </motion.aside>
      </div>
    </motion.div>
  );
};

export default MissionSection;