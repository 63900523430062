import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Eye, Download, FileText, FileSpreadsheet, FilePresentation } from 'lucide-react';
import sanityClient from '../../sanity';

const translations = {
  en: {
    title: "Programs and Transparency",
    viewDocument: "View Document",
    downloadDocument: "Download",
    noDocumentsAvailable: "No documents are currently available.",
    documentsAvailable: "documents available",
    error: "Error loading documents. Please try again later."
  },
  sq: {
    title: "Programi i Transparencës",
    viewDocument: "Shiko Dokumentin",
    downloadDocument: "Shkarko",
    noDocumentsAvailable: "Aktualisht nuk ka dokumente të disponueshme.",
    documentsAvailable: "dokumente në dispozicion",
    error: "Gabim në ngarkimin e dokumenteve. Ju lutemi provoni përsëri më vonë."
  }
};

const DocumentCategoriesGrid = () => {
  const { i18n } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const t = (key) => translations[i18n.language]?.[key] || translations['en'][key];

  useEffect(() => {
    sanityClient
      .fetch(`
        *[_type == "documentCategory"] | order(order asc) {
          _id,
          customTitle_en,
          customTitle_sq,
          order,
          "documents": documents[] | order(order asc) {
            customTitle_en,
            customTitle_sq,
            order,
            "fileUrl_en": file_en.asset->url,
            "fileUrl_sq": file_sq.asset->url,
            "fileName_en": file_en.asset->originalFilename,
            "fileName_sq": file_sq.asset->originalFilename,
            "extension_en": file_en.asset->extension,
            "extension_sq": file_sq.asset->extension
          }
        }
      `)
      .then((result) => {
        // Ensure documents array exists for each category
        const processedResult = result.map(category => ({
          ...category,
          documents: category.documents || []
        }));
        setCategories(processedResult);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
        setError(t('error'));
        setLoading(false);
      });
  }, [i18n.language]);

  const getFileIcon = (extension) => {
    if (!extension) return <FileText className="text-gray-500" />;
    
    switch(extension.toLowerCase()) {
      case 'pdf':
        return <FileText className="text-red-500" />;
      case 'xls':
      case 'xlsx':
        return <FileSpreadsheet className="text-green-500" />;
      case 'ppt':
      case 'pptx':
        return <FileSpreadsheet className="text-orange-500" />;
      default:
        return <FileText className="text-gray-500" />;
    }
  };

  const getCurrentLanguageData = (item) => {
    if (!item) return { title: '', fileUrl: '', fileName: '', extension: '' };
    
    const lang = i18n.language;
    return {
      title: lang === 'sq' ? item.customTitle_sq || item.customTitle_en : item.customTitle_en || item.customTitle_sq,
      fileUrl: lang === 'sq' ? item.fileUrl_sq || item.fileUrl_en : item.fileUrl_en || item.fileUrl_sq,
      fileName: lang === 'sq' ? item.fileName_sq || item.fileName_en : item.fileName_en || item.fileName_sq,
      extension: lang === 'sq' ? item.extension_sq || item.extension_en : item.extension_en || item.extension_sq
    };
  };

  const isPDF = (extension) => extension?.toLowerCase() === 'pdf';

  const handleDownload = async (fileUrl, fileName) => {
    if (!fileUrl) {
      console.error('No file URL available');
      return;
    }

    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName || 'document';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  if (loading) {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <p>Loading...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <p className="text-red-500">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto mb-32">
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">
          {t('title')}
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {categories?.map((category) => {
            if (!category) return null;
            const documents = category.documents || [];
            
            return (
              <div key={category._id} className="relative">
                <div className={`relative ${activeCategory === category._id ? 'z-50' : 'z-0'}`}>
                  <button
                    onClick={() => setActiveCategory(activeCategory === category._id ? null : category._id)}
                    className={`w-full h-full p-6 rounded-lg transition-all duration-300 ${
                      activeCategory === category._id
                        ? 'bg-[#e41e26] text-white'
                        : 'bg-white text-gray-800 border-l-4 border-[#e41e26] shadow-sm hover:shadow-md'
                    }`}
                  >
                    <h2 className="text-xl font-semibold mb-2">
                      {getCurrentLanguageData(category).title}
                    </h2>
                    <p className="text-sm opacity-75">
                      {documents.length} {t('documentsAvailable')}
                    </p>
                  </button>

                  {activeCategory === category._id && (
                    <div className="absolute left-0 w-full bg-white rounded-lg shadow-lg mt-2">
                      {documents.length > 0 ? (
                        <div className="p-4 space-y-3">
                          {documents
                            .sort((a, b) => (a.order || 0) - (b.order || 0))
                            .map((doc, idx) => {
                              if (!doc) return null;
                              const { title, fileUrl, fileName, extension } = getCurrentLanguageData(doc);
                              const showPreviewIcon = isPDF(extension);

                              return (
                                <div 
                                  key={idx}
                                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200"
                                >
                                  <div className="flex items-center gap-3">
                                    {getFileIcon(extension)}
                                    <span className="font-medium text-gray-800">{title}</span>
                                  </div>
                                  <div className="flex gap-2">
                                    {showPreviewIcon && fileUrl && (
                                      <a
                                        href={fileUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="inline-flex items-center justify-center p-2 text-gray-600 hover:text-[#e41e26] rounded-full hover:bg-red-50"
                                        title={t('viewDocument')}
                                      >
                                        <Eye size={20} />
                                      </a>
                                    )}
                                    {fileUrl && (
                                      <button
                                        onClick={() => handleDownload(fileUrl, fileName)}
                                        className="inline-flex items-center justify-center p-2 text-gray-600 hover:text-[#e41e26] rounded-full hover:bg-red-50"
                                        title={t('downloadDocument')}
                                      >
                                        <Download size={20} />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      ) : (
                        <div className="p-4 text-center text-gray-500">
                          {t('noDocumentsAvailable')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DocumentCategoriesGrid;