import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your JSON files
import enHomeHeader from './locales/en/homeHeader.json';
import sqHomeHeader from './locales/sq/homeHeader.json';
import enHomePage from './locales/en/homePage.json';
import sqHomePage from './locales/sq/homePage.json';
import enMainHeader from './locales/en/mainHeader.json';
import sqMainHeader from './locales/sq/mainHeader.json';
import enFooterComponent from './locales/en/footerComponent.json';
import sqFooterComponent from './locales/sq/footerComponent.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        homeHeader: enHomeHeader,
        homePage: enHomePage,
        mainHeader: enMainHeader,
        footerComponent: enFooterComponent
      },
      sq: {
        homeHeader: sqHomeHeader,
        homePage: sqHomePage,
        mainHeader: sqMainHeader,
        footerComponent: sqFooterComponent
      }
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    ns: ['homeHeader', 'homePage', 'mainHeader', 'footerComponent'],
    defaultNS: 'homeHeader'
  });

export default i18n;