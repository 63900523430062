import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLanguage } from '../../components/languageUtils';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Activity, Shield, Users, Building2, Globe, Target } from 'lucide-react';

const AnimatedSection = ({ children, className }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.section
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0, y: 50 },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut"
          }
        }
      }}
      className={className}
    >
      {children}
    </motion.section>
  );
};

const WhyChooseAIC = ({ loading }) => {
  const location = useLocation();
  const { language, translations } = useLanguage();

  const aicLinks = [
    { name: translations[language].submenus.aboutUs.visionMission, path: `/${language}/${translations[language].routes['about-us']}/${translations[language].routes['vision-and-mission']}` },
    { name: translations[language].submenus.aboutUs.whyChooseAIC, path: `/${language}/${translations[language].routes['about-us']}/${translations[language].routes['why-choose-aic']}` },
    { name: translations[language].submenus.aboutUs.lawsRegulations, path: `/${language}/${translations[language].routes['about-us']}/${translations[language].routes['law-and-regulations']}` },
  ];

  const content = {
    en: {
      title: "ALBANIAN INVESTMENT CORPORATION",
      subtitle: "WHY CHOOSE AIC",
      aboutAlbania: {
        title: "About Albania",
        content: [
          "Albania is experiencing robust economic growth, driven by strategic investments in infrastructure, energy, and tourism. The government's pro-business policies and favorable tax regime have made it an attractive destination for foreign investors.",
          "With a young and skilled workforce, Albania offers significant opportunities in sectors such as agriculture, technology, and renewable energy. The country's strategic location further enhances its appeal, providing easy access to European markets and facilitating international trade.",
          "Albania captivates with its stunning natural beauty, featuring pristine beaches along the Adriatic and Ionian Seas, majestic mountains, and a rich tapestry of historical and cultural landmarks.",
          "It's rapidly emerging as a hub for entrepreneurs, offering a supportive business environment, attractive investment opportunities, and a young, dynamic workforce eager to innovate."
        ]
      },
      keyAdvantages: {
        title: "Key Advantages",
        items: [
          {
            icon: Shield,
            title: "Investment Security",
            description: "Strong legal framework protecting investor interests and ensuring business stability"
          },
          {
            icon: Activity,
            title: "Economic Growth",
            description: "Sustained GDP growth and expanding market opportunities"
          },
          {
            icon: Users,
            title: "Skilled Workforce",
            description: "Young, educated, and multilingual talent pool"
          },
          {
            icon: Building2,
            title: "Infrastructure",
            description: "Modern infrastructure and strategic location for business operations"
          },
          {
            icon: Globe,
            title: "Market Access",
            description: "Gateway to European markets and international trade routes"
          },
          {
            icon: Target,
            title: "Strategic Support",
            description: "Dedicated assistance and guidance throughout your investment journey"
          }
        ]
      },
      investmentSectors: {
        title: "Priority Investment Sectors",
        sectors: [
          {
            title: "Energy & Resources",
            description: "Renewable energy projects, mineral resources, and energy infrastructure development"
          },
          {
            title: "Agriculture & Food Processing",
            description: "Modern farming, organic production, and food processing facilities"
          },
          {
            title: "Tourism & Hospitality",
            description: "Coastal development, cultural tourism, and hospitality services"
          },
          {
            title: "Technology & Innovation",
            description: "IT services, digital infrastructure, and technology parks"
          }
        ]
      }
    },
    sq: {
      subtitle: "PSE TË ZGJIDHNI AIC",
      aboutAlbania: {
        title: "SHQIPËRIA: NJË QENDËR E RE PËR INVESTIME DHE RRITJE EKONOMIKE",
        content: [
          "Shqipëria po përjeton një rritje të fuqishme ekonomike, e nxitur nga investimet strategjike në infrastrukturë, energji dhe turizëm. Politikat pro-biznes të qeverisë dhe regjimi i favorshëm tatimor e kanë bërë atë një destinacion tërheqës për investitorët e huaj.",
          "Me një forcë punëtore të re dhe të kualifikuar, Shqipëria ofron mundësi të rëndësishme në sektorë si bujqësia, teknologjia dhe energjia e rinovueshme. Vendndodhja strategjike e vendit e rrit më tej tërheqjen e saj, duke ofruar akses të lehtë në tregjet evropiane dhe duke lehtësuar tregtinë ndërkombëtare.",
          "Shqipëria magjeps me bukurinë e saj natyrore mahnitëse, duke shfaqur plazhe të paprekura përgjatë deteve Adriatik dhe Jon, male madhështore dhe një tapiceri të pasur të monumenteve historike dhe kulturore.",
          "Shqipëria po shfaqet me shpejtësi si një qendër për sipërmarrësit, duke ofruar një mjedis mbështetës biznesi, mundësi tërheqëse investimi dhe një forcë punëtore të re dhe dinamike të etur për të inovuar."
        ]
      },
      keyAdvantages: {
        title: "Avantazhet Kryesore",
        items: [
          {
            icon: Shield,
            title: "Siguria e Investimeve",
            description: "Kuadër i fortë ligjor që mbron interesat e investitorëve dhe siguron stabilitet në biznes"
          },
          {
            icon: Activity,
            title: "Rritje Ekonomike",
            description: "Rritje e qëndrueshme e PBB-së dhe mundësi në zgjerim të tregut"
          },
          {
            icon: Users,
            title: "Fuqi Punëtore e Kualifikuar",
            description: "Grup talentesh të rinj, të arsimuar dhe shumëgjuhësh"
          },
          {
            icon: Building2,
            title: "Infrastruktura",
            description: "Infrastrukturë moderne dhe vendndodhje strategjike për operacionet e biznesit"
          },
          {
            icon: Globe,
            title: "Akses në Treg",
            description: "Portë hyrëse për tregjet evropiane dhe rrugët ndërkombëtare të tregtisë"
          },
          {
            icon: Target,
            title: "Mbështetje Strategjike",
            description: "Asistencë dhe udhëzim i dedikuar gjatë gjithë rrugëtimit tuaj të investimit"
          }
        ]
      },
      investmentSectors: {
        title: "Sektorët Prioritarë të Investimeve",
        sectors: [
          {
            title: "Energji & Burime",
            description: "Projekte të energjisë së rinovueshme, burime minerale dhe zhvillim i infrastrukturës energjetike"
          },
          {
            title: "Bujqësi & Përpunim Ushqimor",
            description: "Bujqësi moderne, prodhim organik dhe facilitete të përpunimit të ushqimit"
          },
          {
            title: "Turizëm & Mikpritje",
            description: "Zhvillim bregdetar, turizëm kulturor dhe shërbime hotelerike"
          },
          {
            title: "Teknologji & Inovacion",
            description: "Shërbime IT, infrastrukturë dixhitale dhe parqe teknologjike"
          }
        ]
      }
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: { duration: 0.5 }
    }
  };

  return (
    <motion.div 
      className="max-w-7xl mx-auto px-5 py-10"
      initial="hidden"
      animate={loading ? "hidden" : "visible"}
      variants={containerVariants}
    >
      <div className="flex flex-col lg:flex-row justify-between gap-10">
        <div className="flex-1">
          {/* Header Section */}
          <AnimatedSection className="mb-10">
            <div className="flex items-center mb-5">
              <div className="w-10 h-1.5 bg-red-600 mr-2.5"></div>
              <h3 className="text-xl font-bold text-gray-800 uppercase">{content[language].subtitle}</h3>
            </div>
            <h2 className="text-4xl font-bold text-red-600 mt-2.5 mb-7.5">
              {content[language].title}
            </h2>
          </AnimatedSection>
          
          {/* About Albania Section */}
          <AnimatedSection className="mb-10">
            <div className="bg-white rounded-lg overflow-hidden shadow-lg">
              <h3 className="text-2xl font-bold text-white p-6 bg-red-600">
                {content[language].aboutAlbania.title}
              </h3>
              <div className="p-6">
                {content[language].aboutAlbania.content.map((paragraph, index) => (
                  <p key={index} className="mb-4 text-gray-700 leading-relaxed">
                    {paragraph}
                  </p>
                ))}
              </div>
            </div>
          </AnimatedSection>

          {/* Key Advantages Grid */}
          <AnimatedSection className="mb-10">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              {content[language].keyAdvantages.title}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {content[language].keyAdvantages.items.map((advantage, index) => (
                <div 
                  key={index}
                  className="p-6 bg-white rounded-lg shadow-md"
                >
                  <advantage.icon className="w-8 h-8 text-red-600 mb-4" />
                  <h4 className="text-lg font-bold text-gray-800 mb-2">{advantage.title}</h4>
                  <p className="text-gray-600">{advantage.description}</p>
                </div>
              ))}
            </div>
          </AnimatedSection>

          {/* Investment Sectors */}
          <AnimatedSection className="mb-10">
            <h3 className="text-2xl font-bold text-gray-900 mb-6">
              {content[language].investmentSectors.title}
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {content[language].investmentSectors.sectors.map((sector, index) => (
                <div 
                  key={index}
                  className="p-6 bg-white rounded-lg shadow-md border-l-4 border-red-600"
                >
                  <h4 className="text-lg font-bold text-gray-800 mb-2">{sector.title}</h4>
                  <p className="text-gray-600">{sector.description}</p>
                </div>
              ))}
            </div>
          </AnimatedSection>
        </div>
        
        {/* Sidebar - Original Design */}
        <aside className="w-full lg:w-72">
          <nav className="rounded-lg p-6">
            <h3 className="text-2xl font-bold text-gray-900 mb-6 uppercase">
              {translations[language].common.quickLinks}
            </h3>
            <ul className="space-y-4">
              {aicLinks.map((link) => (
                <li key={link.path}>
                  <Link 
                    to={link.path}
                    className={`block py-5 px-6 rounded-lg transition-all duration-300 ease-in-out text-lg font-medium
                      ${location.pathname === link.path 
                        ? 'bg-red-600 text-white shadow-md' 
                        : 'text-gray-600 hover:bg-gray-200'}`}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>
      </div>
    </motion.div>
  );
};

export default WhyChooseAIC;