// src/App.js
import React from 'react';
import ProjectsShowcase from "./ProjectsShowcase";

import './Projects.css';


function Contact() {
  return (
    <div className="App">
    <ProjectsShowcase/>
    </div>
  );
}

export default Contact;