import React, { useState, useEffect, useContext } from 'react';
import sanityClient from '../../sanity';
import { GlobalLanguageContext } from '../../components/LanguageSwitcher';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CareersPage = () => {
  const { globalLanguage } = useContext(GlobalLanguageContext);
  const [careerPosts, setCareerPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentCareerIndex, setCurrentCareerIndex] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    experience: '',
    coverLetter: ''
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isAnimating, setIsAnimating] = useState(false);
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const content = {
    en: {
      careersTitle: "Careers",
      readMore: "Read More",
      noCareersAvailable: "Currently, there are no career opportunities available.",
      loading: "Loading...",
      errorMessage: "Failed to load posts.",
      workWithUs: "WORK WITH US!",
      talentSearch: "AIC is in continuous search for talent in various fields, including engineers, architects, urban planners, financiers, lawyers, project managers, and technical experts, with the aim of realizing the Corporation's objectives.",
      sendResume: "We invite you to send a resume and a letter of interest to hr@aicorporation.al.",
      employmentInfo: "If your experience aligns with AIC's needs, we will contact you to further advance your professional engagement within AIC. Employment at AIC is conducted according to the provisions of the Labor Code.",
      name: "Name",
      email: "Email",
      phone: "Phone",
      relevantExperience: "Relevant Experience",
      coverLetter: "Cover Letter",
      uploadResume: "Upload Resume",
      uploadCoverLetter: "Upload Cover Letter",
      submitApplication: "Submit Application",
      defaultImageAlt: "Career Opportunity"
    },
    sq: {
      careersTitle: "Karriera",
      readMore: "Lexo më shumë",
      noCareersAvailable: "Aktualisht, nuk ka mundësi karriere të disponueshme.",
      loading: "Duke ngarkuar...",
      errorMessage: "Dështoi në ngarkimin e postimeve.",
      workWithUs: "PUNONI ME NE!",
      talentSearch: "AIC është në kërkim të vazhdueshëm të talenteve në fusha të ndryshme, duke përfshirë inxhinierë, arkitektë, urbanistë, financierë, avokatë, menaxherë projektesh dhe ekspertë teknikë, me qëllim realizimin e objektivave të Korporatës.",
      sendResume: "Ju ftojmë të dërgoni një rezume dhe një letër interesi në hr@aicorporation.al.",
      employmentInfo: "Nëse përvoja juaj përputhet me nevojat e AIC, ne do t'ju kontaktojmë për të avancuar më tej angazhimin tuaj profesional brenda AIC. Punësimi në AIC kryhet sipas dispozitave të Kodit të Punës.",
      name: "Emri",
      email: "Email",
      phone: "Telefon",
      relevantExperience: "Përvoja Relevante",
      coverLetter: "Letra e Motivimit",
      uploadResume: "Ngarko CV",
      uploadCoverLetter: "Ngarko Letrën e Motivimit",
      submitApplication: "Dërgo Aplikimin",
      defaultImageAlt: "Mundësi Karriere"
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  useEffect(() => {
    setLoading(true);
    sanityClient.fetch(
      `*[_type == "careerPost" && language == $language] | order(publishDate desc) {
        title,
        slug,
        mainImage {
          asset->{
            _id,
            url
          },
          alt
        },
        body,
        publishDate,
        "excerpt": pt::text(body)[0...200]
      }`,
      { language: globalLanguage }
    )
    .then((data) => {
      setCareerPosts(data);
      setLoading(false);
    })
    .catch((err) => {
      console.error(err);
      setError(content[globalLanguage].errorMessage);
      setLoading(false);
    });
  }, [globalLanguage]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    // Add your form submission logic here
  };

  const nextSlide = () => {
    if (isAnimating || (isMobile ? currentCareerIndex + 1 >= careerPosts.length : currentCareerIndex + 3 >= careerPosts.length)) return;
    setIsAnimating(true);
    setCurrentCareerIndex((prevIndex) => isMobile ? prevIndex + 1 : Math.min(prevIndex + 3, careerPosts.length - 3));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prevSlide = () => {
    if (isAnimating || currentCareerIndex === 0) return;
    setIsAnimating(true);
    setCurrentCareerIndex((prevIndex) => isMobile ? prevIndex - 1 : Math.max(prevIndex - 3, 0));
    setTimeout(() => setIsAnimating(false), 500);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const renderNewsItem = (post) => (
    <motion.div 
      key={post.slug.current} 
      className="bg-white border border-gray-200 p-4 flex flex-col transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg h-[340px]"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <img
        src={post.mainImage?.asset?.url || 'https://via.placeholder.com/300x150'}
        alt={post.mainImage?.alt || content[globalLanguage].defaultImageAlt}
        className="w-full h-[150px] object-cover mb-4"
      />
      <div className="flex flex-col flex-grow">
        {post.publishDate && (
          <p className="text-sm text-gray-500 mb-2">
            {formatDate(post.publishDate)}
          </p>
        )}
        <h3 className="text-lg font-semibold text-red-600 mb-2 line-clamp-2">{post.title}</h3>
        <p className="text-sm text-gray-600 flex-grow overflow-hidden line-clamp-3">{post.excerpt}</p>
        <div className="mt-auto pt-2">
          <a href={`/${globalLanguage === 'en' ? 'en/news' : 'sq/lajme'}/${post.slug.current}`} className="text-right text-sm font-bold text-red-600 hover:text-red-700 transition duration-300">
            {content[globalLanguage].readMore}
          </a>
        </div>
      </div>
    </motion.div>
  );

  const renderCareerSection = () => (
    <motion.div 
      className="mb-10 relative"
      initial="hidden"
      animate={controls}
      variants={{
        hidden: { opacity: 0 },
        visible: { 
          opacity: 1,
          transition: { 
            when: "beforeChildren",
            staggerChildren: 0.2
          }
        }
      }}
    >
      <h2 className="bg-red-600 text-white py-2 px-4 text-lg font-normal mb-4">
        {content[globalLanguage].careersTitle}
      </h2>
      <div className="bg-gray-100 p-4">
        {careerPosts.length > 0 ? (
          isMobile ? (
            <>
              {renderNewsItem(careerPosts[currentCareerIndex])}
              {careerPosts.length > 1 && (
                <div className="flex justify-between mt-4">
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={prevSlide} 
                    disabled={currentCareerIndex === 0 || isAnimating}
                  >
                    ❮
                  </button>
                  <button 
                    className="bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
                    onClick={nextSlide} 
                    disabled={currentCareerIndex + 1 >= careerPosts.length || isAnimating}
                  >
                    ❯
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {careerPosts.slice(currentCareerIndex, currentCareerIndex + 3).map(post => renderNewsItem(post))}
            </div>
          )
        ) : (
          <div className="text-gray-500 italic p-4">
            {content[globalLanguage].noCareersAvailable}
          </div>
        )}
      </div>
      {!isMobile && careerPosts.length > 3 && (
        <>
          <button 
            className="absolute top-1/2 -left-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={prevSlide} 
            disabled={currentCareerIndex === 0 || isAnimating}
          >
            ❮
          </button>
          <button 
            className="absolute top-1/2 -right-5 transform -translate-y-1/2 bg-red-600 text-white w-10 h-10 flex items-center justify-center disabled:bg-gray-400 disabled:cursor-not-allowed"
            onClick={nextSlide} 
            disabled={currentCareerIndex + 3 >= careerPosts.length || isAnimating}
          >
            ❯
          </button>
        </>
      )}
    </motion.div>
  );

  if (loading) return <div className="text-center py-10">{content[globalLanguage].loading}</div>;
  if (error) return <div className="text-center py-10 text-red-600">{error}</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 font-sans text-gray-800">
      {renderCareerSection()}

      {/* Application Form Section */}
      <motion.div 
        ref={ref}
        className="max-w-3xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-xl"
        initial="hidden"
        animate={controls}
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { 
            opacity: 1, 
            y: 0,
            transition: { duration: 0.5 }
          }
        }}
      >
        <h1 className="text-4xl font-bold mb-6 text-red-600 text-center">{content[globalLanguage].workWithUs}</h1>
        <div className="mb-8 text-gray-700 text-center">
          <p className="mb-4">{content[globalLanguage].talentSearch}</p>
          <p className="mb-4">{content[globalLanguage].sendResume}</p>
          <p className="mb-4">{content[globalLanguage].employmentInfo}</p>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-gray-700 font-bold mb-2">{content[globalLanguage].name}</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-gray-700 font-bold mb-2">{content[globalLanguage].email}</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">{content[globalLanguage].phone}</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              required
            />
          </div>
          <div>
            <label htmlFor="experience" className="block text-gray-700 font-bold mb-2">{content[globalLanguage].relevantExperience}</label>
            <textarea
              id="experience"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              rows="4"
              required
            ></textarea>
          </div>
          <div>
            <label htmlFor="coverLetter" className="block text-gray-700 font-bold mb-2">{content[globalLanguage].coverLetter}</label>
            <textarea
              id="coverLetter"
              name="coverLetter"
              value={formData.coverLetter}
              onChange={handleChange}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
              rows="6"
              required
            ></textarea>
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">{content[globalLanguage].uploadResume}</label>
            <input type="file" className="w-full" />
          </div>
          <div>
            <label className="block text-gray-700 font-bold mb-2">{content[globalLanguage].uploadCoverLetter}</label>
            <input type="file" className="w-full" />
          </div>
          <motion.button 
            type="submit" 
            className="w-full mt-6 bg-red-600 text-white font-bold py-2 px-4 rounded-md hover:bg-red-700 transition duration-300"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {content[globalLanguage].submitApplication}
          </motion.button>
        </form>
      </motion.div>
    </div>
  );
};

export default CareersPage;